import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalCart(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    data:"",
    demo_order:false
  });

  const [allow,setAllow] = useState("");
  const [desc,setDesc] = useState("")
  const [notes,setNotes] = useState([])
  const [note_id,setNoteId] = useState("")




  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setDesc(searchValue);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("cart");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setDesc("");
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.dm ==true){
        setAllow("on")
      }else{
        setAllow("off")

      }
      get_notes()
      myModal = new bootstrap.Modal(
        document.getElementById("cart"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  async function get_notes() {
 
 
    let response: any = await api("/settings/get_admin_note_options");
    if (response.status_code == 200) {
      console.log(response)
        setNotes(response.response.data)
   
    }
  }


  async function Save() {

    let pass_data = {
    
      post: {

        notes: desc,
        user_id: props.data.value,
        response: note_id!= "0" ? note_id :""
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/update_user_cart_response", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.index= props.data.value;
    item.data= props.data.data;
    item.demo_order=data_res.response.ecommerce_cart_response
    setDesc("");
    // item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }


  const handleApplicationChange = (event: any) => {
    console.log(event.target.value)
    setNoteId(event.target.value)
  };

  return (
    <div
      className="modal fade"
      id="cart"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Update User Cart Response
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          <select
              className="form-select p-2 border-radius"
              value={note_id}
              onChange={handleApplicationChange}
            >
               <option value="" disabled hidden>Select Response</option>
              {notes?.map((ele, i) => (
                <option key={i} value={ele?.id}>
                  {ele?.name}
                </option>
              ))}

<option  value="0">
                  Other
                </option>
            </select>
            <div>
           <div className="m-2">Uodate User Cart Response</div> 
          <textarea className="form-control" value= {desc} onChange={handleInputChange}/>
          </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
