export const AppConfig = {
  base_url: {
    api: 'https://'+ window.location.hostname +'/app', // for live
    // api: "https://1sdev.ecomadmin.1superapp.com/app", //for localhoast
    superapp: "https://",
  },
  user_agent: {
    superapp: {
      android: "",
      ios: ""
      
    },
  },
  diet_type: {
    diet_type: 115,
    vegetarian: 116,
    non_vegetarian: 117,
    eggetarian: 118,
    vegan: 119,
  },
  // environment : "Development",
  environment : "Production",

  api : "AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE",//online
  // api:"AIzaSyBoTKRUmdtQEc7JtrEBUUmGYrVTfeLK_w8",// development
  // api:"AIzaSyC37EQ5KAX63pqvDFLC5EOSe3znJ0ebFVg",//1st



  // Govind Token
  // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6IjY4NmU4NGE2ZjAxMzUzMWIwNjM5MDJjMTAwNzBkZjdiIiwidXNlcm5hbWUiOiJHb3ZpbmRhcmFqIiwibmFtZSI6IkdvdmluZGFyYWoifQ.fjHOTQJ-AU8T-Iig0vv_TjXyPU86kPuKVJT9VEyLwcCLFyKDkqmkbsB7NedjQ5msUD0lOMS5piKrKaAuJbpCWbvKNtYBhJC8LrjWK0FytC7CssWaF6mfCtRWGiHzzORcNPNXj5jBs81ctC4U6J587bO4PCzdjnS6v6j0Q2xnxkQKALfVI8Fz_K2JASw3PpZ2dJsfVDhUJj5b4gllEQJhMCTkD60wIn3yLbSMwMyqAIXB4nQt6K4So6rYO_aaJTe6I2AChCEgZKLJPfSm6oQOqk9B3fbE3MRyunuq9p_S7rh8Evbmxi9diOAuDwsPW-4vWn4qsm6gkcC1W5Y05oIMag",
  
  // token : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6ImMwZmVlMzRkNmI5MTgxNjI4YjZhNDE5MjdiNDA3ZTk4IiwidXNlcm5hbWUiOiJHb3ZpbmRhcmFqIiwibmFtZSI6IkdvdmluZGFyYWoifQ.ErP5GvvJDFdb_4KKgBO0P47N6YOO6H5y_iyzE1AfAYbLwlIsZ_SnveI6xVuSVWilg8j4OBLyhZKcSQ9NC6sOT0qak8oUxsEUU-bIRoO75VmbZdJnJqlXH8XvRUXb4JoYGN9OkmlFf1uh4qwlR1nqFwoghrcTAvDT1QiEn_XzPLYUaZ1Y74l-NsNCRQkVyj7_V1gz6-kQIFH8bC0wg_0FXX8NtrjpkHIxaRAi4Amq0Cnu0n4xNp4H0KhyTPcMEeu1S9GWyWnXCurZDxHLv6mF2OY5yNGhNMhP9QF81xql1YU71sgzIRTErg17vVVpjJbeMFsr8je5DJJyycfHBWLiVQ",
  // token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6Ijc2NWE0ZTA5OWNmNDZjZmE0YTc4ZGU3Mjc2NjdkNjBiIiwidXNlcm5hbWUiOiJzdWthbnQiLCJuYW1lIjoiU3VrYW50YSBEYXNndXB0YSJ9.OJrt-W7ziS4OnyVON68xndCR3vXv_4Mh04UU3BcdXO2K0PJz6-f4ZRJXSIHdZ_Btux9GMI4OXGBxFfmEl0AvSseh625uS6iuL76TOzNYebGcYFM8iEZ71dIAvd5oHiD5i5pYv-sn5MWSLG4HCWoIzQxYOgSo5mMo95q-stROh8ySwQTu2Csm3I_hyCPe8zzwJyz62csis_EB2BAojjJZfLBEJK6VaRVARLymt1NnIjiIQu4b9fdQbIy6Zl1CRt9IzME4zG7wFE0it9j371RzfORPPKsQ9glidXlKV0PNi5f-hDSM-wCWWPk0Vn-cCdgeixsQmfIeSvq03-kWAeZfMA",
  CDN_Media_url : "https://cdn3.1superapp.com/media/",
  CDN_Image_url : "https://cdn2.1superapp.com/images/",
  // environment : "Production",\

 

};
