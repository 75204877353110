import React, { useState, useEffect, useContext, useRef } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import ModalCreateQr from "./ModalCreateQr/ModalCreateQr";
import ModalViewQr from "./ModalViewQr/ModalViewQr";
// import ModalScanner from "../../components/ModalScanner/ModalScanner";
import toast, { Toaster } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalPrintQr from "./ModalPrintQr";
import ModalUserScanned from "./ModalUserScanned/ModalUserScanned";

export default function BrandManageProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);
  const [multipleQrGenerate, SetMultipleQrGenerate]:any = useState(9);
  const [multipleQrGenerateInc, SetMultipleQrGenerateInc]:any = useState(0);
  const [id_value, SerIdValue] = useState(useParams());
  const [qr_data, SetQrData]: any = useState([]);
  const [statistics_data, SetStatisticsData]: any = useState([]);

  const [is_loading, SetIsLoading] = useState(true);
  const [overview_is_loading, SetOverviewIsLoading] = useState(true);


  
  const [modal_create_qr, SetModalCreateQr] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_view_qr, SetModalViewQr] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_qr_scanned, SetModalQrScanned] = useState({
    is_open: false,
    data: "",
   
  });
  const [modal_print_qr, SetModalPrintQr] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [outlet_list, SetOutletList] = useState([]);
  const [selected_outlet, SetSelectedOutlet] = useState({});
  const [modal_scanner_data, SetModalScannerData] = useState({
    is_open: false,
    // from: "company",
    data: {},
  });
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [selectedIds, SetSelectedIds]: any = useState([]);
  const [selectedId, SetSelectedId]: any = useState({});
  const now = new Date();

  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(startOfToday));
  const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(endOfToday));
  const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(startOfToday)));
  const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(endOfToday)));
  const ref = useRef(null);
  let qr_initial_data: any ;
  // = {
  //   data: props.data.qr_link,
  //   width: 100,
  //   height: 100,
  //   // data: " ",
  //   margin: 0,
  //   image: "",
  //   dotsOptions: {
  //     type: "square",
  //     color: "#000",
  //     gradient: null,
  //   },
  //   dotsOptionsHelper: {
  //     colorType: {
  //       single: true,
  //       gradient: false,
  //     },
  //     gradient: {
  //       linear: true,
  //       radial: false,
  //       color1: "#000",
  //       color2: "#000",
  //       rotation: "0",
  //       colorStops: [
  //         { offset: 0, color: "#000" },
  //         { offset: 1, color: "#000" },
  //       ],
  //       type: "linear",
  //     },
  //   },
  //   cornersSquareOptions: {
  //     type: "",
  //     color: "#000",
  //     gradient: null,
  //   },
  //   cornersSquareOptionsHelper: {
  //     colorType: {
  //       single: true,
  //       gradient: false,
  //     },
  //     gradient: {
  //       linear: true,
  //       radial: false,
  //       color1: "#000",
  //       color2: "#000",
  //       rotation: "0",
  //       colorStops: [
  //         { offset: 0, color: "#000" },
  //         { offset: 1, color: "#000" },
  //       ],
  //       type: "linear",
  //     },
  //   },
  //   cornersDotOptions: {
  //     type: "",
  //     color: "#000",
  //     gradient: null,
  //   },
  //   cornersDotOptionsHelper: {
  //     colorType: {
  //       single: true,
  //       gradient: false,
  //     },
  //     gradient: {
  //       linear: true,
  //       radial: false,
  //       color1: "#000",
  //       color2: "#000",
  //       rotation: "0",
  //       colorStops: [
  //         { offset: 0, color: "#000" },
  //         { offset: 1, color: "#000" },
  //       ],
  //       type: "linear",
  //     },
  //   },
  //   backgroundOptions: {
  //     color: "#fff",
  //     gradient: null,
  //   },
  //   backgroundOptionsHelper: {
  //     colorType: {
  //       single: true,
  //       gradient: false,
  //     },
  //     gradient: {
  //       linear: true,
  //       radial: false,
  //       color1: "#ffffff",
  //       color2: "#ffffff",
  //       rotation: "0",
  //       colorStops: [
  //         { offset: 0, color: "#ffffff" },
  //         { offset: 1, color: "#ffffff" },
  //       ],
  //       type: "linear",
  //     },
  //   },
  //   imageOptions: {
  //     hideBackgroundDots: true,
  //     imageSize: 0.4,
  //     margin: 0,
  //     crossOrigin: "anonymous",
  //     // crossOrigin: "use-credentials",
  //   },
  //   qrOptions: {
  //     typeNumber: "0",
  //     mode: "Byte",
  //     errorCorrectionLevel: "Q",
  //   },
  // };
  let qrCode: any;
  useEffect(() => {
    console.log("QrCodeList on mount:", props);
    SetPageNo((prevalue) => {
      let send_data = {
        page_no: prevalue,
        have_more: false,
        startDate:startDate,
        endDate:endDate,
      };
      get_qrcode(send_data);
      return prevalue;
    });

    let send_data = {
      startDate:startDate,
      endDate:endDate,
    };
    get_overview(send_data);

    // get_outlets_and_channels();
  }, []);

  useEffect(() => {
    if(parseInt(multipleQrGenerateInc) > 0){
      generate_qrcode(true);
    }
  }, [multipleQrGenerateInc]);


  function getFormattedDateTime(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }
  async function get_outlets_and_channels() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/outlet_channel_list", pass_data);
    console.log("/brand/outlet_channel_list response :", response.response);
    if (response.status_code == 200) {
      let outlet_data: any = response.response.outlets;
      outlet_data.map((ele: any, index: any) => {
        ele.lable = ele.name;
        ele.value = ele.id;
        if (index == 0) {
          SetSelectedOutlet(ele);
        }
      });
      SetOutletList(outlet_data);
    }
  }

  async function get_qrcode(get_data: any) {

    if (!get_data.have_more) {
      SetIsLoading(true);
    }
    let pass_data = {
      get: {
        // brand: id_value.brand_id,
        page: get_data.page_no,
        filter_from:get_data.startDate,
        filter_to: get_data.endDate,
      },
    };
    let response: any = await api("/qr/sellers", pass_data);
    console.log("/qr/sellers response :", response.response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        let qr_data = response.response.data;

        
        if (get_data.have_more) {
          SetQrData((prevalue: any) => {
            qr_data.map((ele: any) => {
              prevalue.push(ele);
            });
            return prevalue;
          });
        } else {
          SetQrData(qr_data);
        }
      }

      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }

      if (response.response.hasOwnProperty("next_page")) {
        if (response.response.next_page) {
          SetPageNo((prevalue) => {
            prevalue = prevalue + 1;
            return prevalue;
          });
        }
        SetNextPage(response.response.next_page);
      }

      SetIsLoading(false);
    }
  }

  async function get_overview(get_data: any) {

   SetOverviewIsLoading(true)
    let pass_data = {
      get: {
        filter_from:get_data.startDate,
        filter_to: get_data.endDate,
      },
    };
    let response: any = await api("/qr/overview", pass_data);
    console.log("/qr/overview response :", response.response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("statistics")) {
        let statistics = response.response.statistics;

        console.log(statistics);
        SetStatisticsData(statistics);
      }

     

      SetOverviewIsLoading(false);
    }
  }


  async function generate_qrcode(multi:any = false) {
    let pass_data = {
      post: {
        // brand: id_value.brand_id,
        // page: get_data.page_no,
      },
    };
    let response: any = await api("/qr/generate_seller_qr", pass_data);
    console.log("/qr/generate_seller_qr response :", response.response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        let qr_data = response.response.data;
        SetQrData(qr_data);

        // if (get_data.have_more) {
        //   SetQrData((prevalue: any) => {
        //     qr_data.map((ele: any) => {
        //       prevalue.push(ele);
        //     });
        //     return prevalue;
        //   });
        // } else {
        //   SetQrData(qr_data);
        // }
      }

      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }

      if (response.response.hasOwnProperty("next_page")) {
        if (response.response.next_page) {
          SetPageNo((prevalue) => {
            prevalue = prevalue + 1;
            return prevalue;
          });
        }
        SetNextPage(response.response.next_page);
      }
      if(multi){
        if(parseInt(multipleQrGenerate) > 0){
          SetMultipleQrGenerateInc((prev:number) => {
            prev = prev-1;
            return prev;
          })
        }
      }
      SetIsLoading(false);
    }
  }
  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
      
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
          };
          get_qrcode(send_data);
          SetPageNo(1);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }
  };

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="d-flex align-items-center">
          <div className="">
            <h6 className="fs-5  mb-0">Qr Code</h6>
          </div>
        
          {/* scan qr / create new */}
          {/* {!is_loading ? ( */}
            <div className="d-flex justify-content-end ms-auto px-0 text-end ">
              {/* <button
                className="btn bg-prime text-white btn-radius px-3 btn-sm"
                onClick={() => {
                  console.log("Scan qr 123");
                  SetModalScannerData((prevValue) => {
                    prevValue.is_open = true;
                    // prevValue.from = "";
                    // prevValue.data = {};
                    prevValue.data = {
                      outlet_list: outlet_list,
                      selected_outlet: selected_outlet,
                    };
                    return { ...prevValue };
                  });
                }}
              >
                Scan QR
              </button> */}
              <input type="number" className="form-control w-auto me-2" onChange={(e:any) => {SetMultipleQrGenerate(e.target.value)}} value={multipleQrGenerate} />
              <button
                className="btn bg-primary text-white btn-radius px-3 btn-sm me-2"
                onClick={() => {
                  console.log("Create new");
                  // SetModalCreateQr((prevalue: any) => {
                  //   prevalue.is_open = true;
                  //   prevalue.data = {
                  //     outlet_list: outlet_list,
                  //     selected_outlet: selected_outlet,
                  //   };
                  //   prevalue.type = "";
                  //   return { ...prevalue };
                  // });
                  // generate_qrcode(true)
                  SetMultipleQrGenerateInc(multipleQrGenerate);
                  SetIsLoading(true);
                }}
              >
                Generate Multiple
              </button>
              <button
                className="btn bg-primary text-white btn-radius px-3 btn-sm me-2"
                onClick={() => {
                  console.log("Create new");
                  // SetModalCreateQr((prevalue: any) => {
                  //   prevalue.is_open = true;
                  //   prevalue.data = {
                  //     outlet_list: outlet_list,
                  //     selected_outlet: selected_outlet,
                  //   };
                  //   prevalue.type = "";
                  //   return { ...prevalue };
                  // });
                  generate_qrcode()
                  SetIsLoading(true);
                }}
              >
                Generate One
              </button>
              <button type="button" disabled={selectedIds.length <= 0 ? true : false} className="btn btn-secondary me-2" onClick={() => {
                SetSelectedIds([])
                SetSelectedId({})
              }}>Unselect All</button>
              <button type="button" className="btn btn-primary me-2" disabled={selectedIds.length <= 0 ? true : false} onClick={() => {
                SetModalPrintQr((prev: any) => {
                  prev.is_open = true;
                  prev.data = selectedIds
                  return { ...prev };
                })
              }}>Print Selected QR</button>
              <button type="button" className="btn btn-primary" onClick={() => {
                SetModalPrintQr((prev: any) => {
                  prev.is_open = true;
                  let qrs_ids:any = [];
                  if(qr_data.length > 0){
                    qrs_ids = qr_data.slice(0,9)
                  }
                  prev.data = qrs_ids
                  return { ...prev };
                })
              }}>Print Bulk QR</button>
            </div>
          {/* ) : null} */}
        </div>
<div className="d-flex-lg m-2">
<div></div>
        <div className="ms-lg-auto">  
<div className="d-flex-lg">
  <div className="m-2"> Statistics Filter :</div>
        <div className="me-2">
          {console.log(startDate)}
        <input type="datetime-local"
          onChange={handleStartChange}
        id="startDateTime"
        className="form-control"
        value={startDateShow}
        name="startDateTime" required/>

                {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
            </div>
          
            <div>
            <input type="datetime-local"
          onChange={handleStartChange}
        id="endDateTime"
        className="form-control"
        value={endDateShow}
        onChange={handleEndChange}
        name="endDateTime" required/>
{/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
            </div>
            <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button>
            </div>
        </div>
        </div>
      </div>
      <div className="container mt-1 mx-1">
        <h6>Overview</h6>
        {overview_is_loading?
         <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-lg-3">
          <div className="card p-1 cursor my-1">
            <div>
              <Skeleton height={20} width={100} />
            </div>
            <div>
              <Skeleton height={20} width={150} />
            </div>
          </div>
          </div>
            ))}
        </div>:
        <div className="row">
  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Scanned</div>
      <div className="text-end fw-bold">{statistics_data?.total_scanned}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total New Scanned</div>
      <div className="text-end fw-bold">{statistics_data?.total_new_scanned}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Re-Scanned</div>
      <div className="text-end fw-bold">{statistics_data?.total_re_scanned}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Signup</div>
      <div className="text-end fw-bold">{statistics_data?.total_signup}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total App Signup</div>
      <div className="text-end fw-bold">{statistics_data?.total_app_signup}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Android Signup</div>
      <div className="text-end fw-bold">{statistics_data?.total_android_signup}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total iOS Signup</div>
      <div className="text-end fw-bold">{statistics_data?.total_ios_signup}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Scanned App</div>
      <div className="text-end fw-bold">{statistics_data?.total_scanned_app}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Scanned Android</div>
      <div className="text-end fw-bold">{statistics_data?.total_scanned_android}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Scanned iOS</div>
      <div className="text-end fw-bold">{statistics_data?.total_scanned_ios}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Rescanned App</div>
      <div className="text-end fw-bold">{statistics_data?.total_rescanned_app}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Rescanned Android</div>
      <div className="text-end fw-bold">{statistics_data?.total_rescanned_android}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Rescanned iOS</div>
      <div className="text-end fw-bold">{statistics_data?.total_rescanned_ios}</div>
    </div>
  </div>

  {/* New Scanned Section */}
  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total New Scanned App</div>
      <div className="text-end fw-bold">{statistics_data?.total_newscanned_app}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total New Scanned Android</div>
      <div className="text-end fw-bold">{statistics_data?.total_newscanned_android}</div>
    </div>
  </div>

  <div className="col-lg-2 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total New Scanned iOS</div>
      <div className="text-end fw-bold">{statistics_data?.total_newscanned_ios}</div>
    </div>
  </div>
</div>
  
    
    
}
        </div>
      <div
        className={
          OutletContext ? " px-md-3 px-sm-0" : " px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="mt-1 mb-5">
          {is_loading ? (
            // skeleton
            <div className="row">
            {[1, 2, 3, 4, 5, 6].map((ele, i) => (
            <div className="card  p-3 m-2">
            <Skeleton height={10} width={150} />
            <Skeleton height={10} width={200} /> 
            
            <Skeleton height={10} width={150} />
            
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={180} />
            
            <div className="text-center pt-3">
              <Skeleton height={30} width={400} /> 
            </div>
          </div>
            ))}
          </div>
          ) : Object.keys(qr_data).length > 0 ? (
            <div className="row d-flex">
              <InfiniteScroll
                dataLength={qr_data.length}
                next={() => {
                  SetPageNo((prevalue) => {
                    let send_data = {
                      page_no: prevalue,
                      have_more: true,
                      startDate:startDate,
                      endDate:endDate
                    };
                    get_qrcode(send_data);
                    return prevalue;
                  });
                }}
                // hasMore={get_next_page()}
                hasMore={next_page}
                loader={
                  <div className="center m-5">
                    <div className="lds-dual-ring"></div>
                    <p className=" small ps-2">Just a second ...</p>
                  </div>
                }
              >
                {qr_data.map((ele: any) => (
                  <div className="card  p-3 m-1">
                    <p>Id : {ele.id}</p>
                    <p>Qr Link :{ele.qr_link}</p>
                    {ele.hasOwnProperty("outlet_name") ? (
                      <p>Outlet name :{ele.outlet_name}</p>
                    ) : null}
                    <p>Downloaded : {ele.downloaded}</p>
                    <p>Created At : {ele.date}</p>
                    <div className="d-flex mt-2">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          SetModalViewQr((prevalue) => {
                            prevalue.is_open = true;
                            prevalue.data = {
                              qr_link: ele.qr_link,
                              id: ele.id,
                              all_data: ele,
                            };
                            prevalue.type = "";
                            return { ...prevalue };
                          });
                        }}
                      >
                        View
                      </button>
                      <button
                        className="btn btn-primary w-100 ms-2"
                        onClick={() => {
                          SetModalQrScanned((prevalue) => {
                            prevalue.is_open = true;
                            prevalue.data = {
                           
                              id: ele.id,
                        
                            };
                          
                            return { ...prevalue };
                          });
                        }}
                      >
                        User Scanned
                      </button>
                      {(selectedId.hasOwnProperty(ele.id) && selectedId[ele.id] != '') ? (
                        <button
                          className="btn btn-danger w-100 ms-1"
                          onClick={() => {
                            SetSelectedIds((prevalue: any) => {
                              let newArr: any = [];
                              prevalue.map((prevalueObj: any) => {
                                if (prevalueObj.id != ele.id) {
                                  newArr.push(prevalueObj);
                                }
                              });
                              return [...newArr];
                            });
                            SetSelectedId((prevalue: any) => {
                              prevalue[ele.id] = '';
                              return { ...prevalue };
                            });
                          }}
                        >
                          Remove From Print
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-100 ms-1"
                          onClick={() => {
                            if(selectedIds.length < 9){
                              SetSelectedIds((prevalue: any) => {
                                prevalue.push(ele);
                                return [...prevalue];
                              });
                              SetSelectedId((prevalue: any) => {
                                prevalue[ele.id] = true;
                                return { ...prevalue };
                              });
                            } else {
                              toast.error("9 items only allowed");
                            }
                          }}
                        >
                          Add To Print
                        </button>
                      )}

                    </div>
                    <p>Actual Link : {ele.actual_link}</p>
                    <p>Linked Time : {ele.linked_time}</p>

                    

                    <p>Store Name : {ele.store_name}</p>
            <p>Brand Name : {ele.brand_name}</p>
            <p>Brand Id : {ele.brand_id}</p>


            <b>Statistics</b>
            <p>Total Android Signup : {ele.statistics.total_android_signup}</p>
            <p>Total App Signup : {ele.statistics.total_app_signup}</p>
            <p>Total Ios Signup : {ele.statistics.total_ios_signup}</p>
            <p>Total New Scanned : {ele.statistics.total_new_scanned}</p>
            <p>Total Scanned Android : {ele.statistics.total_scanned_android}</p>
            <p>Total Newscanned Android : {ele.statistics.total_newscanned_android}</p>
            <p>Total Newscanned App : {ele.statistics.total_newscanned_app}</p>
            <p>Total Newscanned Ios : {ele.statistics.total_newscanned_ios}</p>
            <p>Total Rescanned : {ele.statistics.total_re_scanned}</p>
            <p>Total Rescanned Android : {ele.statistics.total_rescanned_android}</p>

            <p>Total Rescanned App : {ele.statistics.total_rescanned_app}</p>

            <p>Total Rescanned Ios : {ele.statistics.total_newscanned_ios}</p>

            <p>Total Scanned : {ele.statistics.total_scanned}</p>
            <p>Total Scanned Android : {ele.statistics.total_scanned_android}</p>

            <p>Total Scanned App : {ele.statistics.total_scanned_app}</p>

            <p>Total Scanned Ios : {ele.statistics.total_scanned_ios}</p>

            <p>Total Signup : {ele.statistics.total_signup}</p>

                  </div>
                ))}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="text-center">
              {/* <img
                src={require("../../../assets/icon/empty.png")}
                className="empty-img pb-2"
              /> */}
              <p className="v-small mb-0">No Data, Please generate qr</p>
            </div>
          )}
        </div>
      </div>

      <ModalCreateQr
        open={modal_create_qr.is_open}
        data={modal_create_qr.data}
        close={(data: any) => {
          console.log("ModalCreateQr on close :", data);
          SetModalCreateQr((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            SetQrData(data.data.whole_data);
            SetModalViewQr((prevalue) => {
              prevalue.is_open = true;
              prevalue.data = {
                qr_link: data.data.qr_link,
                id: data.data.id,
              };
              prevalue.type = "";
              return { ...prevalue };
            });
          }
        }}
      />

      <ModalViewQr
        open={modal_view_qr.is_open}
        data={modal_view_qr.data}
        close={(data: any) => {
          console.log("SetModalViewQr on close :", data);
          SetModalViewQr((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = "";
            prevValue.type = "";
            return { ...prevValue };
          });
          if (data.action == "save") {
          }
        }}
      />
         <ModalUserScanned
        open={modal_qr_scanned.is_open}
        data={modal_qr_scanned.data}
        close={(data: any) => {
          console.log("SetModalViewQr on close :", data);
          SetModalQrScanned((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = ""
            return { ...prevValue };
          });
          if (data.action == "save") {
          }
        }}
      />
      <ModalPrintQr
        open={modal_print_qr.is_open}
        data={modal_print_qr.data}
        close={(data: any) => {
          SetModalPrintQr((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = "";
            prevValue.type = "";
            return { ...prevValue };
          });
          if (data.action == "save") {
          }
        }}
      />
      <Toaster />
    </div>
  );
}