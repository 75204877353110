import React, { useState, useEffect, useContext } from "react";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AppConfig } from "../../config";
import ChooseBrandOutletModal from "./ChooseBrandOutletModal";



function MakeSettlement() {
    const [brand_modal, setBrandModal] = useState({
        is_open: false,
        data: {},
    });
    const [seller, setSeller]: any = useState({});
    const [filter, setFilter]: any = useState('settlement_unpaid');
    const [filterLists, setFilterLists]: any = useState([
        {
            slug: "settlement_unpaid",
            name: "Settlement Unpaid",
            checked: false
        },
        {
            slug: "settlement_partially_paid",
            name: "Settlement Partially Paid",
            checked: false
        },
        {
            slug: "settlement_unpaid_partially_paid",
            name: "Settlement Partially Paid & Unpaid",
            checked: false
        },
        {
            slug: "settlement_unapproved",
            name: "Settlement unapproved",
            checked: false
        },
        {
            slug: "settlement_not_eligible",
            name: "Settlement Not Eligible",
            checked: false
        },
        {
            slug: "non_pickupdrop_completed",
            name: "Completed",
            checked: false
        },
        {
            slug: "non_pickupdrop_all",
            name: "All Orders",
            checked: false
        },
    ]);
    const [search, setSearch]: any = useState('');
    const [orders, setOrders]: any = useState([]);
    const [searchIn, setSearchIn]: any = useState('');
    let newTimeoutId: any;
    const [selectedOrders, setSelectedOrders]: any = useState([]);
    const [selectedOrdersObj, setSelectedOrdersObj]: any = useState({});
    const [firstAction, setFirstAction] = useState('');
    const [settlementAmount, setSettlementAmount]: any = useState();
    const [settlementNotes, setSettlementNotes]: any = useState('');
    const [orderTotalAmount, setOrderTotalAmount]: any = useState(0);

    const [filter_data, setFilterData] = useState("");
    const [page_no, SetPageNo] = useState(1);
    const [width, SetWidth] = useState(innerWidth);

    const [next_page, SetNextPage] = useState(false);
    const [viewpanel_index, setViewPanelIndex] = useState(null);

    const [sort_val, SetSort] = useState("asc")
    const [visible_content, SetVisibleContent] = useState(false);
    const [viewPanelData, setViewPanelData]: any = useState();
    const [viewPanel, setViewPanel] = useState(false);
    const [transaction_details, SetTransactionDetails]: any = useState({});
    const Spinner = () => <div className="loader-spinner"></div>;


    useEffect(() => {
        setBrandModal({
            is_open: true,
            data: {},
        });
    }, []);

    useEffect(() => {
        if (seller?.outlet_id) {
            initialLoad();
            let pass_data = {
                order_status: 1,
                seller_brand_id: seller.brand_id,
                seller_outlet_id: seller.outlet_id,
                sort: "",
                page_no: 1,
            };
            getSellerOrders(pass_data);
        }
    }, [seller]);

    useEffect(() => {
        if (seller?.outlet_id) {
            setOrders([]);
            SetVisibleContent(false);
            SetPageNo(1);
            getSellerOrders({ page_no: 1 });
        }
    }, [search, sort_val, filter]);

    useEffect(() => {
        if (firstAction == 'order') {
            updateTotal();
        }
        updateOrderTotal();
    }, [selectedOrdersObj]);


    function initialLoad() {
        setOrders([]);
        SetSort('asc');
        setSearch('');
        setSearchIn('');
        SetPageNo(1);
        SetVisibleContent(false);
        setSelectedOrders([]);
        setSelectedOrdersObj({});
    }




    async function getSellerOrders(get_data: any) {

        if (!get_data.load_more && !get_data.refresh) {
            SetVisibleContent(false);
        }

        let pass_data = {
            get: {
                sort: sort_val,
                page: get_data.page_no,
                seller_brand_id: seller.brand_id,
                seller_outlet_id: seller.outlet_id,
                search: search,
                filter: filter
            },
        };
        let response: any = await api("/orders/get", pass_data);
        if (response.status_code == 200) {
            SetVisibleContent(true);

            if (response.response.hasOwnProperty("data")) {
                if (get_data.load_more) {
                    setOrders((prevValue: any) => {
                        response?.response?.data?.map((ele: any) => {
                            prevValue.push(ele);
                        });
                        return [...prevValue];
                    });
                } else {
                    setOrders(response.response.data);
                }
            }

            if (response.response.hasOwnProperty("next_page")) {
                SetNextPage(response.response.next_page);
                SetPageNo((prevalue) => {
                    prevalue = prevalue + 1;
                    return prevalue;
                });
            }
        }
    }

    const handleSearchInputChange = (e: any) => {
        setSearchIn(e.target.value);
        if (newTimeoutId) {
            clearTimeout(newTimeoutId);
        }
        newTimeoutId = setTimeout(() => {
            setSearch(e.target.value);
        }, 1000);
    };

    const OrderComponent = (order_prop: any) => {
        const [order, setOrder] = useState(order_prop.data);
        return (
            <>
                <div className="mb-1">
                    <div className="d-flex">
                        <div className="d-flex">
                            {order?.customer?.profile && (
                                <img className="img-fluid me-2" width={35} src={order?.customer?.profile} />
                            )}
                            <div>
                                <div>{order?.customer?.name} #{order?.customer?.user_id}</div>
                                <div className="text-muted">{order?.customer?.username}</div>
                            </div>
                        </div>
                        <div className="ms-auto text-end">
                            <div>Order Id: {order?.id}</div>
                            {/* <div>Bill: &#x20b9;{order?.total_amount_payable}</div> */}
                            <div>Shipment ID: {order?.shipments?.map((shipment: any, i: any) => { return i == 0 ? shipment.id : ', ' + shipment.id })}</div>
                        </div>
                    </div>
                </div>
                <div className="fs-11px mb-1 text-muted">{order?.date}</div>
                <div className="d-flex mb-3">
                    <div>
                        {order?.status && (
                            <div className="badge-2 text-primary">{order?.status}</div>
                        )}
                    </div>
                    <div className="ms-auto">
                        {order?.cod_order == true &&
                            order?.cod_payment_status == true ? (
                            <span className="badge-2 text-primary">COD Paid</span>
                        ) : order?.cod_order == true ? (
                            <span className="badge-2 text-primary">COD</span>
                        ) : order?.payment_type_id == "1" && order?.order_completed == false ? (
                            <span className="badge-2 text-primary">Online</span>
                        ) : order?.payment_type_id == "1" && order?.order_completed == true && (
                            <span className="badge-2 text-primary">Paid</span>
                        )}
                    </div>
                </div>
                <div className="mb-3">
                    <div className="text-uppercase mb-1">Settlements</div>
                    <div className="timeline-1 hrz-line">
                        <div className="d-flex align-items-center">
                            <div className={order?.seller_settlement?.settlement_eligible ? "timeline-dot active" : "timeline-dot"}>
                                {order?.seller_settlement?.settlement_eligible && (<img src={AppConfig.CDN_Media_url + "be453854bc89e35e154efa5e26814afa.svg"} className="img-fluid" width={10} />)}
                            </div>
                            <div className="timeline-line-container"><div className={order?.seller_settlement?.settlement_approved ? "timeline-line active" : "timeline-line"}></div></div>
                            <div className={order?.seller_settlement?.settlement_approved ? "timeline-dot active" : "timeline-dot"}>
                                {order?.seller_settlement?.settlement_approved && (<img src={AppConfig.CDN_Media_url + "be453854bc89e35e154efa5e26814afa.svg"} className="img-fluid" width={10} />)}
                            </div>
                            <div className="timeline-line-container"><div className={order?.seller_settlement?.settlement_paid ? "timeline-line active" : "timeline-line"}></div></div>
                            <div className={order?.seller_settlement?.settlement_paid ? "timeline-dot active" : "timeline-dot"}>
                                {order?.seller_settlement?.settlement_paid && (<img src={AppConfig.CDN_Media_url + "be453854bc89e35e154efa5e26814afa.svg"} className="img-fluid" width={10} />)}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="w-100">
                                <span className="fs-11px">Eligible</span>
                                {order?.seller_settlement?.settlement_paid ? (
                                    <div className="fw-500 fs-15">&#8377; {order?.amount} <div className="fs-11px">Balance: &#8377; {(order?.amount - order?.seller_settlement?.settlement_paid_amount > 0) ? order?.amount - order?.seller_settlement?.settlement_paid_amount : 0}</div></div>
                                ) : (
                                    <div className="fw-500 fs-15">&#8377; {order?.amount}</div>
                                )}
                            </div>
                            <div className="w-100 text-center fs-11px">Approved</div>
                            <div className="w-100 text-end">
                                <span className="fs-11px">Paid</span>
                                {order?.seller_settlement?.settlement_paid && (
                                    <div className="fw-500 fs-15">
                                        &#8377; {order?.seller_settlement?.settlement_paid_amount}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!order_prop?.hide && (
                    <>
                        {order?.ordered_products.length > 0 && (
                            <div className="mb-2 fs-11px">
                                <div className="text-uppercase mb-1">Products</div>
                                <div>{order?.ordered_products.map((product: any, j: any) => { return j == 0 ? product.quantity + ' x ' + product.variant_name : ', ' + product.quantity + ' x ' + product.variant_name })}</div>
                            </div>
                        )}
                        {(order?.shipments.length > 0) && (
                            order?.shipments.map((shipment: any, j: any) => (
                                shipment?.super_delivery_order.length > 0 && (
                                    shipment?.super_delivery_order.map((super_delivery_orders: any, k: any) => (
                                        super_delivery_orders?.delivery_partner.map((delivery_partners: any, l: any) => (
                                            delivery_partners.currently_working && (
                                                <div className="mb-3">
                                                    <div className="text-uppercase mb-1 fs-11px">Delivery Partner</div>
                                                    <div className="d-flex">
                                                        <div className="d-flex">
                                                            {delivery_partners?.delivery_partner?.profile && (
                                                                <img className="img-fluid me-2" width={35} src={delivery_partners?.delivery_partner?.profile} />
                                                            )}
                                                            <div>
                                                                <div>{delivery_partners?.delivery_partner?.name}</div>
                                                                <div className="text-muted">{delivery_partners?.delivery_partner?.username}</div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto fs-11px text-muted">
                                                            {delivery_partners?.status}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))
                                    ))
                                )
                            ))
                        )}
                    </>
                )}
            </>
        )
    };

    function updateTotal() {
        let amount = 0;
        Object.keys(selectedOrdersObj).forEach(function (key, index) {
            if (selectedOrdersObj[key].amount) {
                amount += parseFloat(selectedOrdersObj[key].amount);
            }
        });
        setSettlementAmount(amount);
    }
    function updateOrderTotal() {
        let amount = 0;
        Object.keys(selectedOrdersObj).forEach(function (key, index) {
            if (selectedOrdersObj[key].amount) {
                amount += parseFloat(selectedOrdersObj[key].amount);
            }
        });
        setOrderTotalAmount(amount);
    }

    async function submitSettlement() {
        let add_orders: any = [];
        Object.keys(selectedOrdersObj).forEach(function (key, index) {
            if (selectedOrdersObj[key].amount) {
                add_orders.push({
                    id: selectedOrdersObj[key].id,
                    amount: parseFloat(selectedOrdersObj[key].amount),
                    notes: selectedOrdersObj[key].notes,
                });
            }
        });
        if (orderTotalAmount <= settlementAmount && seller?.outlet_id && settlementAmount > 0) {

            let pass_data: any = {
                post: {
                    brand_id: seller.brand_id,
                    outlet_id: seller.outlet_id,
                    amount: settlementAmount,
                    notes: settlementNotes
                },
            };
            if (add_orders.length > 0) {
                pass_data.post.add_orders = add_orders;
            }
            let response: any = await api("/sellers/settlement_paid", pass_data);
            if (response.status_code == 200) {
                setSeller({});
                setFilter('settlement_unpaid');
                setSelectedOrders([]);
                setSelectedOrdersObj({});
                setFirstAction('');
                setSettlementAmount();
                setSettlementNotes('')
                setOrderTotalAmount(0)
                initialLoad();
            }
        }
    }

    const numberInputOnWheelPreventChange = (e: any) => {
        e.target.blur()
        e.stopPropagation()
    }

    return (
        <>
            <div className="head-bar row">
                <div className="col-md-6">
                    <h5 className="  mb-2">Make Seller Settlement</h5>
                </div>
                <div className="col-md-6 col-12">

                </div>
            </div>
            <div className="mt-2 p-2">
                <div className="card cursor p-3" onClick={() => {
                    setBrandModal({
                        is_open: true,
                        data: {},
                    });
                }}>
                    {seller?.brand_id ? (
                        <div className="d-flex">
                            <div>
                                <h5>{seller.brand_name}</h5>
                                <label>{seller.outlet_name}</label>
                            </div>
                            <img className="img-fluid ms-auto" width="20" src={AppConfig.CDN_Media_url + '21d3a9e5cedf31f69753210bf5015802.svg'} />
                        </div>
                    ) : (
                        <div className="d-flex">
                            <h6 className="mb-0">Choose Brand</h6>
                            <img className="img-fluid ms-auto" width="20" src={AppConfig.CDN_Media_url + '52793b013af765bbad0d412942fcef42.svg'} />
                        </div>
                    )}
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card p-3 mh-250">
                            <div className="d-flex mb-2">
                                <h6 className="mb-0">Orders</h6>
                                <div className="dropdown ms-auto">
                                    <button
                                        className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src={require("../../assets/icon/filter.png")}
                                            className="icon-14px"
                                        />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <button className="dropdown-item" type="button" onClick={() => SetSort("asc")}>Oldest</button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" type="button" onClick={() => SetSort("desc")}>Newest</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <input type="search" className="form-control w-50 me-1" placeholder="Search" value={searchIn} onChange={handleSearchInputChange} />
                                <div className="">
                                    <select className="form-select w-100" onChange={(e) => { setFilter(e.target.value) }}>
                                        {filterLists.map((filterList: any) => (
                                            <option value={filterList.slug}>{filterList.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {visible_content ? (
                                (orders.length > 0) ? (
                                    <InfiniteScroll
                                        dataLength={orders.length}
                                        next={() => {
                                            let pass_data = {
                                                load_more: true,
                                                page_no: page_no
                                            };
                                            getSellerOrders(pass_data);
                                        }}
                                        hasMore={next_page}
                                        loader={
                                            <div className="center m-5">
                                                <div className="lds-dual-ring"></div>
                                                <p className=" small ps-2">Just a second ...</p>
                                            </div>
                                        }
                                    >
                                        {orders.map((order: any, i: any) => (
                                            <div className="border mb-2 p-2" key={i}>
                                                <OrderComponent data={order} />
                                                <div className="">
                                                    <button type="button" className="btn btn-primary w-100" disabled={(selectedOrdersObj.hasOwnProperty(order.id) && selectedOrdersObj[order.id]?.id)} onClick={() => {
                                                        let balance_amount = order?.amount - order?.seller_settlement?.settlement_paid_amount;
                                                        let ord: any = {
                                                            id: order.id,
                                                            data: order,
                                                            amount: balance_amount,
                                                            notes: null,
                                                            balance: balance_amount,
                                                        };
                                                        setSelectedOrders((prev: any) => { prev.push(ord); return [...prev]; });
                                                        setSelectedOrdersObj((prev: any) => {
                                                            prev[order.id] = ord;
                                                            return { ...prev };
                                                        });
                                                        if (firstAction == '' || firstAction == 'order') {
                                                            setFirstAction('order');
                                                            setSettlementAmount((prev: any) => {
                                                                if (prev) {
                                                                    return prev + balance_amount;
                                                                } else {
                                                                    return balance_amount;
                                                                }
                                                            });
                                                        }

                                                    }}>Add</button>
                                                </div>
                                            </div>
                                        ))}
                                    </InfiniteScroll>
                                ) : (
                                    <div className="py-5 text-center">
                                        No Orders
                                    </div>
                                )
                            ) : (
                                <div className="py-5 text-center">
                                    Orders will appear here
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card p-3 mh-250 right-panel-2">
                            <h6>Picked Orders For Settlement</h6>
                            {selectedOrders.map((selectedOrder: any, i: any) => (
                                <div className="border mb-2 p-2" key={i}>
                                    <OrderComponent data={selectedOrder.data} hide={true} />
                                    <div className="mb-2">
                                        <div className="mb-1">
                                            <label>Amount</label>
                                            <input type="number" className="form-control" onWheel={numberInputOnWheelPreventChange} value={selectedOrdersObj[selectedOrder.id].amount}
                                                onChange={(e) => {
                                                    setSelectedOrdersObj((prev: any) => {
                                                        prev[selectedOrder.id].amount = e.target.value;
                                                        return { ...prev };
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <label>Notes</label>
                                            <textarea className="form-control"
                                                onChange={(e) => {
                                                    setSelectedOrdersObj((prev: any) => {
                                                        prev[selectedOrder.id].notes = e.target.value;
                                                        return { ...prev };
                                                    });
                                                }}
                                            >
                                                {selectedOrdersObj[selectedOrder.id].notes}
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="">
                                        <button type="button" className="btn btn-danger w-100" onClick={() => {
                                            setSelectedOrders((prevs: any) => {
                                                let newData: any = [];
                                                if (prevs.length > 0) {
                                                    prevs.map((prev: any) => {
                                                        if (prev.id != selectedOrder.id) {
                                                            newData.push(prev);
                                                        }
                                                    });
                                                }
                                                return [...newData];
                                            });
                                            setSelectedOrdersObj((prev: any) => {
                                                prev[selectedOrder.id] = {};
                                                return { ...prev };
                                            });
                                            if (firstAction == '' || firstAction == 'order') {
                                                updateTotal();
                                            }
                                        }}>Remove</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card p-3 mh-250 right-panel-2">
                            <h6>Summary</h6>
                            <div className="mb-2">
                                <div className="mb-1">
                                    <label>Total Payable Amount</label>
                                    <input type="number" className="form-control" onWheel={numberInputOnWheelPreventChange} value={settlementAmount}
                                        onChange={(e) => {
                                            setFirstAction('amount');
                                            setSettlementAmount(e.target.value);
                                        }}
                                    />
                                    {orderTotalAmount > settlementAmount && (
                                        <div className="fs-11px text-danger mt-1">Order total is more than Payable amount, &#x20b9;{orderTotalAmount}</div>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        onChange={(e) => {
                                            setSettlementNotes(e.target.value);
                                        }}
                                        rows={5}
                                    >
                                        {settlementNotes}
                                    </textarea>
                                </div>
                                <button type="button" className="btn btn-success w-100" disabled={orderTotalAmount > settlementAmount || !(seller?.outlet_id) || !(settlementAmount > 0)}
                                    onClick={submitSettlement}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChooseBrandOutletModal
                open={brand_modal.is_open}
                close={(data: any) => {
                    setBrandModal({
                        is_open: false,
                        data: {},
                    });
                    if (data.action == "save") {
                        setSeller(data.data);
                    }
                }}
            />
        </>
    )
}

export default MakeSettlement;