
import React, { useState, useEffect, useContext } from "react";
import { api } from "../../utils/Api";
import { format, render, cancel, register } from "timeago.js";
import ModalDemoUser from "./ModalDemoUser";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalCod from "./ModalCod";
import ModalRemarks from "./ModalRemarks";
import ModalCart from "./ModalCart";




import {
    Ionicons,
    MaterialIcons,
    MaterialCommunityIcons,
  } from "@expo/vector-icons";
import { common } from "@material-ui/core/colors";

function SuperappUsers() {
  const [order_data, SetOrderData] = useState([]);
  const [filter_data, setFilterData] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [loader, setLoader] = useState(false);
  const [width, SetWidth] = useState(innerWidth);

  const [demo_modal, setDemoModal] = useState({
    is_open: false,
    data: {},
  });
  const [cod_modal, setCodModal] = useState({
    is_open: false,
    data: {},
  });
  const [remark_modal, setRemarkModal] = useState({
    is_open: false,
    data: {},
  });
  const [cart_modal, setCartModal] = useState({
    is_open: false,
    data: {},
  });
  const [next_page, SetNextPage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [order_index, setOrderIndex] = useState(null);

  const [sort_val, SetSort] = useState("")
  const [visible_content, SetVisibleContent] = useState(false);
  const [show_details_loader, SetShowDetailsLoader] = useState(true);
  const [transaction_able, SetTransactionAble] = useState(false);
  const [transaction_details, SetTransactionDetails]: any = useState({});
  const Spinner = () => <div className="loader-spinner"></div>;


    
  useEffect(() => {
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: page_no,
    };
    get_users(pass_data, filter_data);
  }, []);

  const sort = (val:any) =>{
    disable();
    SetSort(val);
    SetPageNo(1)

    let pass_data = {
      sort: val,
      page_no: 1,
    };
    
    get_users(pass_data, filter_data);


  }

  const OrderSkeleton = () => {
    return (
    //   <div className="card p-3 cursor">
    //     <div className="row align-items-center">
    //       <div className="col-6 pb-3">
    //         <Skeleton height={10} width={50} />
    //       </div>
    //       <div className="col-6 text-end">
    //         <Skeleton height={30} width={100} />
    //       </div>
    //     </div>

    //     {/* <div className="row pt-2">
    //       <div className="col-6 pb-2">
    //         <Skeleton height={10} width={50} />
    //         <Skeleton height={10} width={120} />
    //       </div>
    //       <div className="col-6 pb-2 text-end">
    //         <Skeleton height={10} width={120} />
    //         <Skeleton height={10} width={100} />
    //       </div>
    //     </div> */}

    //     <div className="pb-3 pt-2">
    //       <hr className="text-gray m-0" />
    //     </div>

    //     <div className=" pb-2">
    //       <Skeleton height={10} width={120} />
    //     </div>

    //     <div className="py-2">
    //       <div className="d-flex pb-3">
    //         <Skeleton height={18} width={18} />
    //         <div className="ps-2">
    //           <Skeleton height={10} width={120} />
    //           <Skeleton height={10} width={180} />
    //         </div>
    //       </div>
    //       {/* <div className="d-flex pb-3">
    //         <Skeleton height={18} width={18} />
    //         <div className="ps-2">
    //           <Skeleton height={10} width={120} />
    //           <Skeleton height={10} width={180} />
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    <div className="card p-12 skeleton-loader">
    <div className="d-flex border-bottom pb-2 mb-2">
      <div>
        <div className="pb-3 d-flex">
          <div className="pe-2">
            <Skeleton height={34} width={34} borderRadius="50%" />
          </div>
          <div>
            <Skeleton height={20} width={100} />
            <Skeleton height={16} width={80} />
          </div>
        </div>
        <div>
          <Skeleton height={16} width={60} />
        </div>
        <div>
          <Skeleton height={20} width={120} />
          <Skeleton height={20} width={120} />
        </div>
      </div>
      <div className="ms-auto text-end">
        <Skeleton height={16} width={80} />
        <Skeleton height={16} width={60} />
        <Skeleton height={16} width={100} />
      </div>
    </div>
    <div className="d-flex justify-content-end">
      <Skeleton height={34} width={150} />
    </div>
  </div>
    );
  };
  function enable(ele: any,i:any) {console.log("hi")
    // SetShowDetailsLoader(true);
    // const timer = setTimeout(() => {
    //   SetShowDetailsLoader(false);
    // }, 500);
    SetTransactionAble(true);
    setOrderIndex(i);
    SetTransactionDetails(ele);
    return () => clearTimeout(timer);
  }
  
  function disable() {
    SetShowDetailsLoader(false);
    SetTransactionAble(false);
  }

  async function get_users(get_data: any, search: any) {
 
    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }
    {get_data.refresh &&
      setLoader(true)
    }
    let pass_data = {
      get: {
        // order_status: get_data.order_status,
        sort:get_data.sort,
        page: get_data.page_no,
        search: search,
      },
    };
    let response: any = await api("/settings/superapp_users", pass_data);
    if (response.status_code == 200) {
      
// console.log(response.response)
setLoader(false)
disable()
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetOrderData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetOrderData(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

    //   if (response.response?.ordered_product_spec) {
    //     SetProductsDietType((prevValue: any) => {
    //       return { ...prevValue, ...response?.response?.ordered_product_spec };
    //     });
    //   }
    }
  }

  const handleInputChange = (e: any) => {
   
    const searchValue = e.target.value;
   
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      SetPageNo(1)
      // Call your API with the search value
      setFilterData(searchValue);
      let pass_data = {
        sort: "",
        page_no: 1,
        // order_status:order_status
      };
console.log("hi")
      get_users(pass_data, searchValue);
      disable();
    }, 1000); // 5 milliseconds delay
 
    setTimeoutId(newTimeoutId);
  };

    const handleCall = (e,fullMobile) => {
      e.stopPropagation()
      if (fullMobile) {
        window.location.href = `tel:${fullMobile}`;
      }
    };

    const handleEmail = (e,email) => {
      e.stopPropagation()
      if (email) {
        window.location.href = `mailto:${email}`;
      }
    };

    
    const DeliveryPartnerCard = () => {
      return (
        <div className="col-md-6 sm-mt-6 sm-pb-5">
        <InfiniteScroll
                    dataLength={order_data.length}
                    next={() => {
                      let pass_data = {
                        // order_status: order_status,
                        seller_brand_id: "",
                        seller_outlet_id: "",
                        sort: "",
                        load_more: true,
        
                        page_no: page_no,
                      };
                      console.log("no")
                      get_users(pass_data, filter_data);
                    }}
                    hasMore={next_page}
                    loader={
                      <div className="center m-5">
                        <div className="lds-dual-ring"></div>
                        <p className=" small ps-2">Just a second ...</p>
                      </div>
                    }
                  >
                       {visible_content ? (
                      order_data.length != 0 ? (
        <div >
                          {order_data?.map((ord_ele: any, ord_index: any) => (
                            <div 
                            // className="col-md-4 col-12 mb-3"
                             key={ord_index}>
        
        <div className="card p-12 mb-2"      onClick={() => {
                                enable(ord_ele,ord_index);
                              
                                // SetSelectedDetails(i);
                              }}>
                                 {/* <Link to={"/orders/" + ord_ele.id}> */}
                                   
                                      <div className="d-flex border-bottom pb-2 mb-2">
                                        <div>
                                        <div className=" pb-3 d-flex">
                                  {ord_ele.hasOwnProperty("profile") && ord_ele.profile != null
                                   ? (
                                    <div className="pe-2">
                                      <img
                                        src={ord_ele.profile}
                                        alt="16.a1d4f3f3"
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                        style={{ borderRadius: "50px" }}
                                      ></img>
                                    </div>
                                  ) : (
                                    <div className="pe-2">
                                      <img
                                        src={require("../../assets/icon/avatar.png")}
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    <p className="text-dark fw-bold fs-7">
                                      {ord_ele?.name}
                                    </p>
                                    <p>{ord_ele?.user_name}</p>
                                  </div>
                                </div>
                                {/* {ord_ele.demo_order && */}
                                <div>
                                            Demo Order User:{" "}
                                            <span className="fw-500">{ord_ele.demo_order ? "Yes":"No"}</span>
                                          </div>
        {/* } */}
        
        <div>
                                            COD:{" "}
                                            <span className="fw-500">
                                            {ord_ele.cod_allowed == "force_allowed"? "force allowed": ord_ele.cod_allowed}
        
        
                                            </span>
                                          </div>
                                          <div>
                                         
                                            <span className="fw-500">
                                            {ord_ele.full_mobile}
        
        
                                            </span>
                                          </div>
        
                                          
        
                                          {/* <div className="mb-2">
                                            <span
                                              className={
                                                ord_ele?.status ==  "Order Placed" || ord_ele?.status =="Seller Accepted"
                                                  ? "badge-1 me-2 badge-blue"
                                                  : ord_ele?.status == "Seller Rejected"
                                                  ? "badge-1 me-2 badge-red"
                                                  : ord_ele?.status == "Auto Cancelled"
                                                  ? "badge-1 me-2 badge-red-2"
                                                  : ord_ele?.status == "Completed"
                                                  ? "badge-1 me-2 badge-green"
                                                  : "badge-1 me-2 badge-red-2"
                                              }
                                            >
                                              {ord_ele?.status}
                                            </span>
                                            
                                            <span className="fs-12">
                                              {ord_ele?.delivery_type
         == "Hyper Local" && (
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "31b719f813a5801b0b9e68653ff6df89.svg"
                                                  }
                                                  className="img-fluid me-1"
                                                  width="14"
                                                />
                                              )}
                                              {ord_ele?.delivery_type}
                                            </span>
                                          </div>
        {ord_ele.dm_order &&
                                          <span
                                              className="badge-1 me-2 badge-blue mt-2"
                                              >Demo</span>
        }
                                          <div className="mb-1 fw-500">
                                            {ord_ele?.seller?.brand_name}
                                          </div>
                                          <div className="mb-1 fw-500">
                                          {ord_ele?.seller?.outlet?.name}
                                          </div>
                                         */}
                                        </div>
                                        <div className="ms-auto text-end">
                                          <div className="mb-1">
                                            {format(ord_ele?.created_timeago, "en_US")}
                                          
                                          </div>
                                          <div>
                                            ID:{" "}
                                            <span className="fw-500">{ord_ele?.id}</span>
                                          </div>
                                          <div>
                                         {ord_ele?.sector}
                                          </div>
        
        <div className="d-flex end">
                                          <div className="call-btn m-2" onClick={(e)=>handleCall(e,ord_ele?.full_mobile)} style={{ cursor: 'pointer' }}>
                <Ionicons
                  name="md-call"
                  size={18}
                  color="#A80032"
                />
              </div>
              {ord_ele?.email &&
                 <div
                  className="call-btn"
                   onClick={(e)=>handleEmail(e,ord_ele?.email)} 
                   style={{ cursor: 'pointer' }}>
                <Ionicons
                  name="md-mail"
                  size={18}
                  color="#A80032"
                />
                                      </div>
        }
                                      </div>
        
                                      </div>
        
                                      </div>
        
                                      {/* <ProductList
                                        products={ord_ele?.ordered_products}
                                        diet_type={products_diet_type}
                                      /> */}
                                     {/* { !ord_ele.demo_order && */}
        
                                  </div>
                                  </div>
        
                          ))}
        </div>
           ) : (
            <div className="center p-3">
              <div className="card p-3 w-100">
                <div className="w-100 text-center">
                  <img
                    src={require("../../assets/img/not-found.png")}
                    className="w-20"
                  />
                </div>
                <p className="center pt-3">No Orders data</p>
              </div>
            </div>
          )
        ) : (
        
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
                    <div className="card mb-2 p-3">
                      <div className="row">
                        <div className="col-6 pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <div className="col-6 text-end pb-2">
                          <Skeleton height={30} width={100} />
                        </div>
                        <div className="col-6 pb-2">
                          <Skeleton height={10} width={50} />
                        </div>
                        <div className="col-6 pb-2 text-end">
                          <Skeleton height={10} width={120} />
                        </div>
                        <div className="col-6 pb-3 d-flex">
                          <div className="pe-2">
                            <Skeleton height={34} width={34} borderRadius={50} />
                          </div>
                          <div className="">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                        </div>
                        <div className="col-6 end pb-3">
                          <Skeleton height={10} width={80} />
                        </div>
                        <div className="px-2 pb-3">
                          <hr className="text-gray m-0" />
                        </div>
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
                      </div>
                    </div>
                  ))
        
        )}
        </InfiniteScroll>
        </div>
      );
    };
  return (
    <>
    <div className="head-bar row">
    <div className="col-md-6">
      <h5 className="  mb-2">
      SuperApp Users</h5>
    </div>
    <div className="col-md-6 col-12">
        <div className="d-flex">
      <div className="input-icons form-group p-2">
        <input
          type="text"
          className="form-control search-bar p-0"
          placeholder="Search here ..."
          onChange={handleInputChange}
        />
        <div className="cursor px-2 border-left">
          <Ionicons name="md-search" size={16} color="#ccc" />
        </div>
      </div>
      <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
          
            </ul>
          </div>

          {loader?
         <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner">
        <Spinner />
        </button>:
          <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
           onClick={()=>{
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: 1,
              refresh:true,
            };
            SetPageNo(1)
           
            get_users(pass_data, filter_data);

           }}> 
           <img
                src={require("../../assets/icon/refresh.png")}
                className="icon-20px"
              /></button>
          }
          </div>
    </div>


  </div>

  <div className="px-3 mt-2 sm-pt-4">
<div className="row">




{width < 768 && transaction_able == false ? (
            <DeliveryPartnerCard />
          ) : width > 768 ? (
            <DeliveryPartnerCard />
          ) : null}
{transaction_able ? (
            <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                {/* {show_details_loader ? (
                  <div className="card bg-fff p-3 mb-3">
                    <div className="mb-4">
                      <Skeleton height={10} width={120} />
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <Skeleton height={10} width={80} />
                        <div className="pt-2 d-flex">
                          <div className="pe-2">
                            <Skeleton
                              height={34}
                              width={34}
                              borderRadius={50}
                            />
                          </div>
                          <div className="">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>

                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                      </div>
                      <div className="border-top pt-3 mt-2">
                        <div className="mb-4">
                          <Skeleton height={10} width={120} />
                        </div>
                        <div className="row">
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ( */}
                 <div className="card p-12 mb-2">
                         {/* <Link to={"/orders/" + ord_ele.id}> */}
                         <div className="d-flex mb-2">
                         <div
                        className="cursor pe-3"
                        onClick={() => {
                          disable();
                        }}
                      >
                        <MaterialCommunityIcons
                          name="arrow-left"
                          size={20}
                          color="black"
                        />
                      </div>
                      <h6 className="mb-0">User Details</h6>
                    </div>
                              <div className="d-flex border-bottom pb-2 mb-2">
                                <div>
                                <div className=" pb-3 d-flex">
                          {transaction_details.hasOwnProperty("profile") && transaction_details.profile != null
                           ? (
                            <div className="pe-2">
                              <img
                                src={transaction_details.profile}
                                alt="16.a1d4f3f3"
                                className="rounded-float-start"
                                height="34"
                                width="34"
                                style={{ borderRadius: "50px" }}
                              ></img>
                            </div>
                          ) : (
                            <div className="pe-2">
                              <img
                                src={require("../../assets/icon/avatar.png")}
                                className="rounded-float-start"
                                height="34"
                                width="34"
                              />
                            </div>
                          )}
                          <div className="">
                            <p className="text-dark fw-bold fs-7">
                              {transaction_details?.name}
                            </p>
                            <p>{transaction_details?.user_name}</p>
                          </div>
                        </div>
                        <div>
                                    Demo Order User:{" "}
                                    <span className="fw-500">{transaction_details.demo_order ? "Yes":"No"}</span>
                                  </div>
{/* } */}

<div>
                                    COD:{" "}
                                    <span className="fw-500">
                                    {transaction_details.cod_allowed == "force_allowed"? "force allowed": transaction_details.cod_allowed}


                                    </span>
                                  </div>
                                  {/* <div className="mb-2">
                                    <span
                                      className={
                                        ord_ele?.status ==  "Order Placed" || ord_ele?.status =="Seller Accepted"
                                          ? "badge-1 me-2 badge-blue"
                                          : ord_ele?.status == "Seller Rejected"
                                          ? "badge-1 me-2 badge-red"
                                          : ord_ele?.status == "Auto Cancelled"
                                          ? "badge-1 me-2 badge-red-2"
                                          : ord_ele?.status == "Completed"
                                          ? "badge-1 me-2 badge-green"
                                          : "badge-1 me-2 badge-red-2"
                                      }
                                    >
                                      {ord_ele?.status}
                                    </span>
                                    
                                    <span className="fs-12">
                                      {ord_ele?.delivery_type
 == "Hyper Local" && (
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "31b719f813a5801b0b9e68653ff6df89.svg"
                                          }
                                          className="img-fluid me-1"
                                          width="14"
                                        />
                                      )}
                                      {ord_ele?.delivery_type}
                                    </span>
                                  </div>
{ord_ele.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
}
                                  <div className="mb-1 fw-500">
                                    {ord_ele?.seller?.brand_name}
                                  </div>
                                  <div className="mb-1 fw-500">
                                  {ord_ele?.seller?.outlet?.name}
                                  </div>
                                 */}
                                </div>
                                <div className="ms-auto text-end">
                                  <div className="mb-1">
                                    {format(transaction_details?.created_timeago, "en_US")}
                                  
                                  </div>
                                  <div>
                                    ID:{" "}
                                    <span className="fw-500">{transaction_details?.id}</span>
                                  </div>
                                  <div>
                                 {transaction_details?.sector}
                                  </div>

                                  <div className="d-flex end">
                                  <div className="call-btn m-2" onClick={(e)=>handleCall(e,transaction_details?.full_mobile)} style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-call"
          size={18}
          color="#A80032"
        />
      </div>
      {transaction_details?.email &&
         <div
          className="call-btn"
           onClick={(e)=>handleEmail(e,transaction_details?.email)} 
           style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-mail"
          size={18}
          color="#A80032"
        />
                              </div>
}
                              </div>
                                </div>
                              </div>

                              <div className="d-flex border-bottom pb-2 mb-2">
                           <div>

                          
                            <div>{transaction_details?.full_mobile}</div>
                            <div>{transaction_details?.gender}</div>
                            <div>{transaction_details?.address}</div>
                            <div>{transaction_details?.pincode}</div>
                            {transaction_details?.blood_group &&
                            <div>
                            Blood Group:{" "}
                                    <span className="fw-500">{transaction_details?.blood_group}</span>
                                  </div>
}
                                  {transaction_details?.default_app &&
                                  <div>
                                  Default App:{" "}
                                    <span className="fw-500">{transaction_details?.default_app}</span>
                                  </div>
}
                                  {transaction_details?.dob &&
                                  <div>
                                  DOB:{" "}
                                    <span className="fw-500">{transaction_details?.dob}</span>
                                  </div>
}
                                  {transaction_details?.email &&
                     
                                  <div>
                                  Email:{" "}
                                    <span className="fw-500">{transaction_details?.email}</span>
                                  </div>
}
                                  {transaction_details?.height &&
                                  <div>
                                  Height:{" "}
                                    <span className="fw-500">{transaction_details?.height}</span>
                                  </div>
}
                                
                                  {transaction_details?.weight &&
                                    <div>
                                  Weight:{" "}
                                    <span className="fw-500">{transaction_details?.weight}</span>
                                  </div>
}

{transaction_details?.ecommerce_remarks.length>0 &&
                                    <div>
                                <b> Ecommerce Remarks:{" "}</b> 
                                <ul>
                                    <div>{transaction_details?.ecommerce_remarks.map((ele,i)=>(

                                      <li>{ele.message}<p>{ele.create_time}</p></li>
                                    ))}</div>
                                    </ul>
                                  </div>
}

{transaction_details?.ecommerce_cart_response.length>0 &&
                                    <div>
                                <b> Ecommerce Cart Response:{" "}</b> 
                                <ul>
                                    <div>{transaction_details?.ecommerce_cart_response.map((ele,i)=>(

                                      <li>{ele.response}<p>{ele.notes}</p><p>{ele.create_time}</p></li>
                                    ))}</div>
                                    </ul>
                                  </div>
}
  
                      

                           
                      






                            
                           </div>
                                <div className="ms-auto text-end">
                                  <div className="mb-1">
                                  {transaction_details?.signup_platform}
                                  
                                  </div>
                                  <div>
                                    User Status:{" "}
                                    <span className="fw-500">{transaction_details?.user_status}</span>
                                  </div>
                                  <div>
                                 {transaction_details?.sector}
                                  </div>
                                </div>
                              </div>

                              <button
                            className="btn btn-blue btn-sm ms-auto "
                            onClick={() => {
                                let set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details.demo_order,
                                    index:order_index
                                    
                                  
                                    
                                  };
                                  setDemoModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update Demo Order
                          </button>
{/* } */}
<button
                            className="btn btn-blue btn-sm ms-auto mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details.cod_allowed,
                                    index: order_index
                                    
                                  
                                    
                                  };
                                  setCodModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update COD Order
                          </button>
                          <button
                            className="btn btn-blue btn-sm ms-auto mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details.cod_allowed,
                                    index: order_index
                                    
                                  
                                    
                                  };
                                  setRemarkModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Add User Remarks
                          </button>
                          <button
                            className="btn btn-blue btn-sm ms-auto mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details.cod_allowed,
                                    index: order_index
                                    
                                  
                                    
                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update Cart Response
                          </button>
                              {/* <ProductList
                                products={ord_ele?.ordered_products}
                                diet_type={products_diet_type}
                              /> */}
                             {/* { !ord_ele.demo_order && */}
                      
{/* } */}
{/* <button
                            className="btn btn-blue btn-sm ms-auto mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: ord_ele.id,
                                    dm: ord_ele.cod_allowed,
                                    index:ord_index
                                    
                                  
                                    
                                  };
                                  setCodModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update COD Order
                          </button> */}
                          </div>
                {/* )} */}
              </div>
            </div>
          ) : null}
</div>




<ModalDemoUser
        open={demo_modal.is_open}
        data={demo_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setDemoModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].demo_order = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />
      <ModalCod
        open={cod_modal.is_open}
        data={cod_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCodModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].cod_allowed = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />
            <ModalRemarks
        open={remark_modal.is_open}
        data={remark_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setRemarkModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].ecommerce_remarks = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />

<ModalCart
        open={cart_modal.is_open}
        data={cart_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCartModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
          
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].ecommerce_cart_response = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />
</div>


</>
  )
}

export default SuperappUsers