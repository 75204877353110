import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
// import { omit } from "lodash";

const animatedComponents = makeAnimated();

const outlet_initial_value = {
  selected_user: {
    id: "",
    name: "",
  },
  user_id: "",
  selected_roles: [],
  roles: { roles: [], custom_roles: [] },
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  cat_name: "",
  image_url: "",
};

let myModal = {};

export default function ModalCreateCategory(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [modal_type, SetModalType] = useState("");
  const [user_errors, SetUserErrors] = useState(false);
  //OutletData End

  useEffect(() => {
    let myModal1: HTMLElement | null = document.getElementById("ModalUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetOutletData(outlet_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(document.getElementById("ModalUser"), {});
      myModal.show();
      SetModalType(props.type);
      get_roles();
      //   get_users(page_no);
      if (props.type == "edit" || props.type == "view") {
        let set_data = {
          // user_id: props.data.value.user_id,
          user_id: props.data.value.id,

          selected_user: {
            id: props.data.value.user_id,
            name: props.data.value.user,
          },
          selected_roles: [],
          roles: { roles: [], custom_roles: [] },
          address:
            props.data.value.hasOwnProperty("address") &&
            props.data.value.address != null
              ? props.data.value.address
              : "",
          mobile:
            props.data.value.hasOwnProperty("mobile") &&
            props.data.value.mobile != null
              ? props.data.value.mobile
              : "",
          whatsapp:
            props.data.value.hasOwnProperty("whatsapp") &&
            props.data.value.whatsapp != null
              ? props.data.value.whatsapp
              : "",
          email:
            props.data.value.hasOwnProperty("email") &&
            props.data.value.email != null
              ? props.data.value.email
              : "",
        };

        SetOutletData(set_data);
      }
    }
  }, [props.open]);

  async function get_roles() {
    let data_res = await api("/orders/sectors");

    let role_set_value = [];
    data_res.response.sectors.map((role_item, role_index) => {
      role_set_value.push({
        label: role_item.name,
        value: role_item.id,
        //   type: "roles",
      });
    });
    SetUserRoleOptions(role_set_value);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);

    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_brand", pass_data);

    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function outlet_value_on_change(name: any, value: any) {
    // validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function add_user() {
    if (outlet_data.cat_name !== "" && outlet_data.selected_roles !== "") {
      let post_value = {
        post: {
          name: outlet_data.cat_name,
          sector: outlet_data.selected_roles?.value,
        },
      };
      if (outlet_data.image_url != "") {
        post_value.post.image_url = outlet_data.image_url;
      }
      if (props.data.id) {
        post_value.post.parent = props.data.id;
      }

      let data_res = await api("/orders/create_category", post_value);

      if (data_res.status_code == 200) {
        let item = close_data;
        item.action = "save";
        // item.value = data_res.response.users[0];

        SetCloseData(item);
        SetOutletData(outlet_initial_value);
        myModal.hide();
      }
    } else {
      SetUserErrors(true);
    }
  }

  async function update_user() {
    let post_value = {
      user_id: outlet_data.user_id,
      roles: {},
      address: outlet_data.address,
      mobile: outlet_data.mobile,
      whatsapp: outlet_data.whatsapp,
      email: outlet_data.email,
    };
    let roles_value = {
      roles: [],
      custom_roles: [],
    };
    outlet_data.selected_roles.map((item, index) => {
      if (item.type == "roles") {
        roles_value.roles.push(item.value);
      } else {
        roles_value.custom_roles.push(item.value);
      }
    });
    post_value.roles = roles_value;
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: post_value,
    };

    let data_res = await api("/brand/edit_brand_user", pass_data);
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "edit";
      item.value = data_res.response.brand_users[0];
      item.index = props.data.index;

      SetCloseData(item);
      SetOutletData(outlet_initial_value);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="ModalUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      // data-bs-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {userOpen ? (
                <div className="d-flex">
                  <img
                    // src={require("../../../assets/icon/svg/arrow-left.svg")}
                    className="cursor action-img px-2"
                    onClick={() => {
                      setUserOpen(false);
                    }}
                  />
                  Select User
                </div>
              ) : modal_type == "add" ? (
                "Add Category"
              ) : modal_type == "edit" ? (
                "Edit User"
              ) : (
                "View User"
              )}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div>
              {userOpen ? (
                <div className="row">
                  <div className="col-12">
                    <label>Select user</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select User Name"
                      defaultValue={text}
                      onChange={(e) => {
                        onTextChanged(e);
                        SetUserErrors(false);
                      }}
                    />
                  </div>

                  <h6 className="mt-4 px-3">Available Users</h6>
                  <div className="users_scroll mt-2 px-4">
                    {user_data.map((item, sugIndex) => (
                      <div
                        className="cursor"
                        key={sugIndex}
                        onClick={() => {
                          SetOutletData((oldValues) => ({
                            ...oldValues,
                            selected_user: item,
                            user_id: item.id,
                          }));
                          setUserOpen(false);
                          SetUserErrors(true);
                        }}
                      >
                        <div className="row align-items-center mb-3">
                          <div className="col-2 col-sm-1 col-md-1 p-md-0">
                            <img
                              src={
                                item.dp_url != null
                                  ? item.dp_url
                                  : ""
                                    // require("../../../assets/img/profile.png")
                              }
                              className="img-radius w-100"
                            />
                          </div>

                          <div className="col-6 col-md-7">
                            <p
                              className={
                                item.exists
                                  ? "text-gray mb-0 fs-6 fw-bolder"
                                  : "text-dark mb-0"
                              }
                            >
                              {item.name}
                            </p>
                          </div>
                          <div className="col-4 p-0 text-end ">
                            {item.exists ? (
                              <small className="text-red mb-0 fs-7">
                                User already exists
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}

                    {next_page ? (
                      <p
                        className="text-blue text-center my-2 cursor"
                        onClick={() => {
                          load_more();
                        }}
                      >
                        Load More
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="userName" className="form-label">
                      Category Name
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        value={outlet_data.cat_name}
                        placeholder="Category Name"
                        onChange={(e) => {
                          outlet_value_on_change("cat_name", e.target.value);
                        }}
                      />

                      {user_errors ? (
                        <small className="text-red">* Required fields</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Sector</label>
                    <Select
                      // closeMenuOnSelect={false}
                      components={animatedComponents}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Sector"
                      //   isMulti??
                      Group
                      value={outlet_data.name}
                      options={user_role_options}
                      onChange={(e, action) => {
                        SetOutletData((oldValues) => ({
                          ...oldValues,
                          selected_roles: e,
                        }));
                        outlet_value_on_change("role", e);
                        SetUserErrors(false);
                      }}
                      isDisabled={props.type == "view" ? true : false}
                    />
                    {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="userName" className="form-label">
                      Image
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={outlet_data.image_url}
                        placeholder="image url"
                        onChange={(e) => {
                          outlet_value_on_change("image_url", e.target.value);
                        }}
                      />

                      {user_errors ? (
                        <small className="text-red">* Required fields</small>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-light-outline"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "add" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={() => {
                      update_user();
                    }}
                  >
                    Update
                  </button>
                ) : modal_type == "view" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={(event) => {
                      SetCloseData(event);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
