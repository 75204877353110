import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import "./SideMenu.css";
import DataContext from "../../utils/Context/DataContext";

import {
  AntDesign,
  Feather,
  Ionicons,
  Entypo,
  Fontisto,
  FontAwesome5,
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Foundation,
} from "@expo/vector-icons";

// import { withTranslation } from "react-i18next";

const menu_initial_value = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
    img: <MaterialIcons name="dashboard" size={18} color="gray" />,
  },
  // {
  //   id: 2,
  //   name: "Sections",
  //   path: "sections",
  //   img: <MaterialCommunityIcons name="arrange-bring-forward" size={18} color="gray" />
  // },
  // {
  //   id: 3,
  //   name: "Products",
  //   path: "products",
  //   img: <Feather name="shopping-cart" size={18} color="gray" />
  // },
  // {
  //   id: 4,
  //   name: "Customers",
  //   path: "customers",
  //   img: <FontAwesome5 name="user-alt" size={18} color="gray" />
  // },
  // {
  //   id: 5,
  //   name: "Collections",
  //   path: "collection",
  //   img: <Ionicons name="bookmarks" size={18} color="gray" />
  // },
  {
    id: 6,
    name: "Shop Orders",
    path: "orders",
    img: <Feather name="shopping-cart" size={18} color="gray" />,
  },
  {
    id: 20,
    name: "Delivery Partner Orders",
    path: "delivery_partner_orders",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 7,
    name: "Live Delivery Partner",
    path: "map",
    img: <Fontisto name="map" size={17} color="gray" />,
  },
  {
    id: 12,
    name: "Payments",
    path: "payment",
    img: <FontAwesome name="rupee" size={18} color="gray" />
  },
  {
    id: 6,
    name: "Seller Settlements",
    path: "seller_settlements",
    img: <AntDesign name="solution1" size={18} color="gray" />,
  },
  {
    id: 6,
    name: "Make Seller Settlements",
    path: "make_settlement",
    img: <AntDesign name="solution1" size={18} color="gray" />,
  },
  {
    id: 8,
    name: "Delivery Partners",
    path: "delivery_partner_cod_settlement",
    img: <FontAwesome name="th-large" size={16} color="gray" />
  },
  {
    id: 14,
    name: "Seller Brands",
    path: "seller_brands",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 17,
    name: "Product Cart Details",
    path: "cart_details",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 17,
    name: "Pickup Drop Cart Details",
    path: "pickup_drop_cart_details",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 19,
    name: "Superapp Users",
    path: "superapp_users",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 8,
    name: "Gallery",
    path: "gallery",
    img: <Foundation name="photo" size={18} color="gray" />
  },
  {
    id: 8,
    name: "Categories",
    path: "categories",
    img: <FontAwesome name="th-large" size={16} color="gray" />
  },
  {
    id: 8,
    name: "Qr Code",
    path: "qr_code",
    img: <FontAwesome name="th-large" size={16} color="gray" />
  },
  {
    id: 13,
    name: "Delivery Partner Request",
    path: "delivery_partner_request",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 16,
    name: "Outlet Activation Request",
    path: "outlet_activation_requests",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 10,
    name: "Product Name/Desc Updates",
    path: "product_name_desc_request",
    img: <FontAwesome name="product-hunt" size={18} color="gray" />
  },
  {
    id: 10,
    name: "Product Update Request",
    path: "product_update_request",
    img: <FontAwesome name="product-hunt" size={18} color="gray" />
  },
  {
    id: 10,
    name: "Product Specificaton Update Request",
    path: "product_Specificaton_update_request",
    img:<MaterialIcons name="mark-chat-unread" size={16} color="gray" />
  },
  {
    id: 15,
    name: "Seller User Roles",
    path: "seller_user_roles",
    img: <Fontisto name="person" size={18} color="gray" />
  },
  {
    id: 18,
    name: "Settings",
    path: "settings",
    img: <Fontisto name="person" size={18} color="gray" />
  },
 
  // {
  //   id: 7,
  //   name: "Settings",
  //   path: "settings",
  //   img: <Ionicons name="ios-settings" size={18} color="gray" />
  // },
  // {
  //   id: 8,
  //   name: "Margin",
  //   path: "margin",
  //   img: <MaterialCommunityIcons name="margin" size={18} color="gray" />
  // },
];

export default function SideMenu(props: any) {
  const { t } = props;
  const context = useContext(DataContext);

  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems] = useState(menu_initial_value);
  const [user_data, SetUserData] = useState({});
  const [id_value, SerIdValue] = useState(useParams());
  const [view_data, SetViewData] = useState({});
  const [width, SetWidth] = React.useState(innerWidth);

  useEffect(() => {


    // SetUserData(context.app_data.user_data);
    // let user_value = localStorage.getItem("user_data");
    // SetUserData(JSON.parse(user_value));
    // let view_value = localStorage.getItem("view_data");
    // SetViewData(JSON.parse(view_value));
  }, []);

  useEffect(() => {
    SetIsActive(props.active);
  }, [props.active]);

  function dropdown_action(m_item: any, m_index: any) {
    let items: any = menu_items;
    items[m_index].is_open = !m_item.is_open;
    SetMenuItems([...items]);
  }
  // function dropdown_action(m_item, m_index) {
  //   // let items = menu_items;
  //   // items.map((item) => {
  //   //   if (item.hasOwnProperty("is_open")) {
  //   //     item.is_open = false;
  //   //   }
  //   // });
  //   // items[m_index].is_open = !m_item.is_open;
  //   // SetMenuItems([...items]);

  // SetMenuItems((items:any) => {
  //   items.map((item, i) => {
  //     if(i != m_index){
  //       if (item.hasOwnProperty("is_open")) {
  //         item.is_open = false;
  //       }
  //     }
  //   });
  //   items[m_index].is_open = !m_item.is_open;
  //   return [...items];
  // })

  return (
    // <div className={is_active ? "sidebar active" : "sidebar"}>
    //   <div className="d-lg-none d-flex">
    //     {/* <i className="fas fa-arrow-left px-3 py-2 text-white"></i> */}
    //     <div
    //       onClick={() => {
    //         props.menu_click(false);
    //       }}
    //     >
    //       <img
    //         src={require("../../assets/icon/svg/x.svg")}
    //         className="p-2 cursor"
    //       />
    //     </div>
    //     <div className="ms-auto">
    //       <img
    //         src={require("../../assets/icon/svg/log-out.svg")}
    //         className="p-2 cursor"
    //       />
    //     </div>
    //   </div>
    //   <div className="navbar-profile">
    //     <div className="fs-5 fw-bolder pt-2">{user_data.name}</div>
    //   </div>
    //   <div className="sidebar-sticky">
    //     <ul>
    //       {menu_items.map((m_item, m_index) =>
    //         m_item.hasOwnProperty("children") ? (
    //           <li className="sidebar-dropdown" key={m_index}>
    //             <a
    //               className={
    //                 m_item.is_open
    //                   ? "sidebar-dropdown-btn active"
    //                   : "sidebar-dropdown-btn"
    //               }
    //               role="button"
    //               onClick={() => {
    //                 dropdown_action(m_item, m_index);
    //               }}
    //             >
    //               <i className="fas fa-cogs"></i> {t(m_item.name)}
    //               <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i>
    //             </a>
    //             <div className="sidebar-dropdown-menu">
    //               <ul>
    //                 {m_item.children.map((m_c_item, m_c_index) => (
    //                   <li key={m_c_index}>
    //                     <NavLink
    //                       className="link-loader"
    //                       activeclassname="link-active"
    //                       to={m_c_item.path}
    //                       onClick={() => {
    //                         dropdown_close();
    //                         props.menu_click(false);
    //                       }}
    //                     >
    //                       <i className="fas fa-gifts"></i> {t(m_c_item.name)}
    //                     </NavLink>
    //                   </li>
    //                 ))}
    //               </ul>
    //             </div>
    //           </li>
    //         ) : (
    //           <li key={m_index}>
    //             <NavLink
    //               className="link-loader"
    //               activeclassname="link-active"
    //               to={m_item.path}
    //               onClick={() => {
    //                 dropdown_close();
    //                 props.menu_click(false);
    //               }}
    //             >
    //               {/* <img src={m_item.img} className="icon-img" />  */}
    //               <div className="d-flex align-items-center">
    //               {m_item.img}
    //               <span className="mb-0 ps-3">{m_item.name}</span>

    //               </div>
    //             </NavLink>
    //           </li>
    //         )
    //       )}
    //     </ul>
    //   </div>
    // </div>

    <div className="pb-5">
      <div className={is_active ? "sidebar active" : "sidebar"}>
        <div className={is_active ? "sidebar-w" : ""}>
          <div className="navbar-profile py-2 sm-pb-2">
            <div className="d-sm-block d-md-none border-bottom">
              <div className="d-flex align-items-center p-2">
                {/* <img src={require("../../assets/img/ecom-admin.jpeg")} className="ecom-logo" /> */}

<img src={require("../../assets/img/logo.png")} className="logo" />
<div className="ps-2">
  <h6 className="text-prime fw-bold mb-0">Admin</h6>
</div>

                {/* <div className="p-2 cursor">
                  <AntDesign name="logout" size={17} color="#777" />
                </div> */}
                {/* <div
                  className=" p-2 cursor ms-auto"
                  onClick={() => {
                    if (width < 786) {
                      props.menu_click(false);
                    }
                  }}
                >
                  <Ionicons name="ios-close" size={20} color="#777" />
                </div> */}
              </div>
            </div>
            {/* <div className="p-2 img-radius">
              {view_data?.profile && (
                <img
                  src={view_data.profile}
                  className="prof-img"
                />
              )}
            </div>
            <h6 className="mb-0 pt-2">{view_data.brand_name}</h6>
            <p className="pt-2 text-darkgray v-small">{view_data.name}</p>
          </div> */}
          </div>

          {/* <div className="fs-5 fw-bolder pt-2">Ecommerce Admin</div> */}

          <div className="sidebar-sticky ps-1 pb-5 md-pt-3 bg-fff">
            <ul>
              {menu_items.map((m_item: any, m_index: any) =>
                m_item.hasOwnProperty("children") ? (
                  <li className="sidebar-dropdown" key={m_index}>
                    {/* <a
                      className={
                        m_item.is_open
                          ? "sidebar-dropdown-btn active"
                          : "sidebar-dropdown-btn"
                      }
                      role="button"
                      onClick={() => {
                        dropdown_action(m_item, m_index);
                      }}
                    > */}
                    {/* <i className="fas fa-cogs"></i>  */}
                    {m_item.name}
                    {/* <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i> */}
                    {/* </a> */}
                    <div className="sidebar-dropdown-menu">
                      <ul>
                        {m_item.children.map(
                          (m_c_item: any, m_c_index: any) => (
                            <li key={m_c_index}>
                              <NavLink
                                className="link-loader"
                                activeclassname="link-active"
                                to={m_c_item.path}
                                onClick={() => {
                                  if (width < 786) {
                                    props.menu_click(false);
                                  }
                                  // dropdown_close();
                                }}
                              >
                                <div className="pe-2 w-25px">{m_c_item.img}</div>
                                {/* <i className="fas fa-gifts"></i> */}
                                {m_c_item.name}
                              </NavLink>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li key={m_index}>
                    <NavLink
                      className="link-loader d-flex align-items-center"
                      activeclassname="link-active"
                      to={m_item.path}
                      onClick={() => {
                        // dropdown_close();
                        if (width < 786) {
                          props.menu_click(false);
                        }
                      }}
                    >
                                <div className="pe-2 w-25px">{m_item.img}</div>
                      {/* <img src={m_item.img} className="icon-img" /> */}
                     {m_item.name}
                    </NavLink>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default withTranslation()(SideMenu);
