import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";

export default function ModalCreateSettlement(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [myModal, setMyModal] = useState<any>(null); // State to store the modal instance
  const [order_list_loading, SetOrderListLoading] = useState(true);
  const [ol_next_page, SetOLNextPage] = useState(false);
  const [ol_page_no, SetOLPageNo] = useState(1);
  const [ol_list, SetOLList] = useState([]);
  const ol_filter_options = [
    // {
    //   id: 1,
    //   value: "all",
    //   name: "All",
    // },
    // {
    //   id: 2,
    //   value: "cod_paid",
    //   name: "Paid",
    // },
    {
      id: 3,
      value: "settlement_unpaid",
      name: "UnPaid",
    },
    {
      id: 4,
      value: "settlement_partially_paid",
      name: "Partially Paid",
    },
    {
      id: 5,
      value: "settlement_unpaid_partially_paid",
      name: "Un Paid & Partially Paid",
    },
  ];
  const [selected_ol_filter, SetSelectedOLFilter] =
    useState("settlement_unpaid");
  const ol_sort_options = [
    {
      id: 1,
      value: "asc",
      name: "Oldest",
    },
    {
      id: 2,
      value: "desc",
      name: "Latest",
    },
  ];
  const [selected_ol_sort, SetSelectedOLSort] = useState("desc");
  const payment_type_options = [
    {
      id: 0,
      value: "",
      name: "All",
    },
    {
      id: 1,
      value: 1,
      name: "Online",
    },
    {
      id: 2,
      value: 2,
      name: "COD",
    },
  ];
  const [selected_payment_type, SetSelectedPaymentType] = useState(2);
  const [total_value_entered, SetTotalValueEntered] = useState(false);
  const [total_amount_value, SetTotalAmountValue] = useState(0);
  const [note_value, SetNoteValue] = useState("");
  const [lrc_error_data, SetLRCErrorData] = useState("");
  const fileInputRef: any = React.createRef();
  const [image_data, SetImageData] = useState([]);

  useEffect(() => {
    let modalInstance = new bootstrap.Modal(
      document.getElementById("CreateSettlement"),
      {}
    );
    setMyModal(modalInstance); // Save the modal instance to the state

    let myModal1 = document.getElementById("CreateSettlement");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetOrderListLoading(true);
      SetOLNextPage(false);
      SetOLPageNo(1);
      SetOLList([]);
      SetSelectedOLFilter("cod_unpaid");
      SetSelectedOLSort("desc");
      SetSelectedPaymentType(2);
      SetTotalValueEntered(false);
      SetTotalAmountValue(0);
      SetNoteValue("");
      SetLRCErrorData("");
      SetImageData([]);

      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal.show();
      let send_data = {
        agent_id: props.data.agent_id,
        page_no: 1,
        filter: selected_ol_filter,
        sort: selected_ol_sort,
        payment_type: selected_payment_type,
      };
      get_order_data(send_data);
    }
  }, [props.open]);

  async function get_order_data(get_data: any) {
    if (!get_data.load_more) {
      SetOrderListLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        // agent_id: get_data.agent_id,
        seller_brand_id: props.data.brand_id,
        seller_outlet_id: props.data.outlet_id,
        filter: get_data.filter,
        sort: get_data.sort,
        // order_status : "all"
        // payment_type: get_data.payment_type,
      },
    };
    let response = await api("/orders/get", pass_data);
    if (response.status_code == 200) {
      SetOrderListLoading(false);
      let l_data = response.response.data;
      if (get_data.load_more) {
        SetOLList((prevValue: any) => {
          l_data.map((item: any) => {
            item.checked = false;
            item.total_value = 0;
            item.textarea = "";
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        SetOLList((prevalue: any) => {
          l_data.map((ele: any) => {
            ele.checked = false;
            ele.total_value = 0;
            ele.textarea = "";
          });
          prevalue = l_data;
          return prevalue;
        });
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetOLNextPage(response.response.next_page);
      }
    }
  }

  function calculate_lrc() {
    SetOLList((prevalue) => {
      SetTotalAmountValue((prevalue1) => {
        console.log("SetTotalAmountValue calculate_lrc :", prevalue1);
        let checked_data = prevalue.filter((ele: any) => ele.checked);
        let checked_total = 0;
        console.log("checked_data calculate_lrc:", checked_data);
        checked_data.map((ele: any) => {
          checked_total = Number(checked_total) + Number(ele.total_value);
        });
        console.log("checked total calculate_lrc:", checked_total);
        console.log("prevalue1 calculate_lrc:", prevalue1);

        if (checked_total > prevalue1) {
          SetLRCErrorData(
            "Selected Order value is Higher than total value,Change any value"
          );
        } else {
          SetLRCErrorData("");
        }
        return prevalue1;
      });
      return [...prevalue];
    });
  }

  function onDrop(event: any) {
    event.preventDefault();

    if (props.disabled) return;

    const files = event.dataTransfer.files;
    let old_value = image_data;
    const array = fileListToArray(files, old_value);
    upload_image(array);
    SetImageData((prevalue) => {
      prevalue = array;
      return [...prevalue];
    });
  }

  function onFilesAdded(evt: any) {
    if (props.disabled) return;
    const files = evt.target.files;
    let old_value = image_data;
    const array = fileListToArray(files, old_value);
    upload_image(array);
    
    SetImageData((prevalue) => {
      prevalue = array;
      return [...prevalue];
    });
  }

  function fileListToArray(list: any, value: any) {
    const old_value = value;
    const array = [];
    for (var i = 0; i < list.length; i++) {
      let data: any = {};
      data.file = list.item(i);
      data.url = URL.createObjectURL(list.item(i));
      data.upload_percentage = 0;
      old_value.push(data);
    }
    return old_value;
  }

  function openFileDialog() {
    if (props.disabled) return;
    fileInputRef.current.click();
  }

  function submit() {
    SetOLList((prevalue) => {
      let checked_data = prevalue.filter((ele: any) => ele.checked);
      if (checked_data.length > 0) {
        let send_data = {
          checked_data: checked_data,
        };
        pay_settlement(send_data);
      } else {
        toast.error("Select orders to submit");
      }
      return [...prevalue];
    });
  }

  async function pay_settlement(get_data: any) {
    let pass_data: any = {
      post: {
        // agent_id: props.data.agent_id,
        brand_id : props.data.brand_id,
        outlet_id : props.data.outlet_id,
        amount: total_amount_value,
        notes: note_value,
        add_orders: [],
        files: [],
      },
    };
    get_data.checked_data.map((ele: any) => {
      let push_data: any = {
        id: ele.id,
        amount: ele.total_value,
      };
      if (ele.textarea != "") {
        push_data.notes = ele.textarea;
      }
      pass_data.post.add_orders.push(push_data);
    });
    let response = await api("/sellers/settlement_paid", pass_data);
    if (response.status_code == 200) {
      let item: any = close_data;
      item.action = "save";
      item.data = props.data;
      SetCloseData(item);

      myModal.hide();
    }
  }

  async function upload_image(get_data: any) {
    const sizeLimit = 10 * 1024 * 1024; // 10MB in bytes
    // let filteredFiles = get_data.filter(
    //   (file: any) => file?.file?.size >= sizeLimit
    // );
    let pass_data = {
      post: {
        file: get_data.file,
      },
    };
    let res_data = await api(
      "/deliverypartners/upload_file",
      pass_data,
      false,
      true
    );
    if (res_data.response.hasOwnProperty("id")) {
    }
  }

  return (
    <div
      className="modal fade"
      id="CreateSettlement"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered ">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">
             {props.title ? props.title:" COD Handover"} 
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-6">
                <h6>Orders</h6>
                {/* filter/sort/pament type */}
                <div className="d-flex">
                  {/* filter */}
                  <label htmlFor="">
                    Filter
                    <select
                      className="form-select"
                      value={selected_ol_filter}
                      onChange={(e: any) => {
                        SetSelectedOLFilter(e.target.value);
                        SetTotalAmountValue(0);
                        SetTotalValueEntered(false);
                        let send_data = {
                          agent_id: props.data.agent_id,
                          page_no: 1,
                          filter: e.target.value,
                          sort: selected_ol_sort,
                          payment_type: selected_payment_type,
                        };
                        SetOLPageNo(1);
                        get_order_data(send_data);
                      }}
                    >
                      {ol_filter_options.map((ele, i) => (
                        <option key={i} value={ele.value}>
                          {ele.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  {/* sort */}
                  <label htmlFor="">
                    Sort
                    <select
                      className="form-select"
                      value={selected_ol_sort}
                      onChange={(e: any) => {
                        SetSelectedOLSort(e.target.value);
                        SetTotalAmountValue(0);
                        SetTotalValueEntered(false);
                        let send_data = {
                          agent_id: props.data.agent_id,
                          page_no: 1,
                          filter: selected_ol_filter,
                          sort: e.target.value,
                          payment_type: selected_payment_type,
                        };
                        SetOLPageNo(1);
                        get_order_data(send_data);
                      }}
                    >
                      {ol_sort_options.map((ele, i) => (
                        <option key={i} value={ele.value}>
                          {ele.name}
                        </option>
                      ))}
                    </select>
                  </label>

                  {/*payment type  */}
                  {props.from != "dp_cod_settlement" ? (
                    <label htmlFor="">
                      Payment type
                      <select
                        className="form-select"
                        value={selected_payment_type}
                        onChange={(e: any) => {
                          SetSelectedPaymentType(e.target.value);
                          SetTotalAmountValue(0);
                          SetTotalValueEntered(false);
                          let send_data = {
                            agent_id: props.data.agent_id,
                            page_no: 1,
                            filter: selected_ol_filter,
                            sort: selected_ol_sort,
                            payment_type: e.target.value,
                          };
                          SetOLPageNo(1);
                          get_order_data(send_data);
                        }}
                      >
                        {payment_type_options.map((ele, i) => (
                          <option key={i} value={ele.value}>
                            {ele.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  ) : null}
                </div>
                {/* <OrderListCard ol_list={ol_list} /> */}
                {/* orders */}
                <div className="">
                  {order_list_loading ? (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, i) => (
                      <div className="card mb-2 p-3" key={i}>
                        <div className="row align-items-center border-bottom">
                          <div className="col-6 pb-3">
                            <Skeleton height={10} width={50} />
                          </div>
                          <div className="col-6 text-end pb-3">
                            <Skeleton height={30} width={100} />
                          </div>
                          <div className="col-6 pb-2">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                          <div className="col-6 pb-2 text-end">
                            <Skeleton height={10} width={80} count={2} />
                          </div>
                        </div>
                        <div className="pt-2">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={180} />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : ol_list.length > 0 ? (
                    <>
                      <InfiniteScroll
                        dataLength={ol_list.length}
                        next={() => {
                          SetOLPageNo((prevalue) => {
                            prevalue = prevalue + 1;
                            let send_data = {
                              agent_id: props.data.agent_id,
                              page_no: prevalue,
                              filter: selected_ol_filter,
                              sort: selected_ol_sort,
                              payment_type: selected_payment_type,
                              load_more: true,
                            };
                            get_order_data(send_data);
                            return prevalue;
                          });
                        }}
                        hasMore={ol_next_page}
                        loader={
                          <div className="center m-5">
                            <div className="lds-dual-ring"></div>
                            <p className=" small ps-2">Just a second ...</p>
                          </div>
                        }
                      >
                        {ol_list.map((ele: any, i: any) => (
                          <div key={i} className="card mb-2 p-3">
                            <div className="row align-items-center">
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="form-control-checkbox"
                                  checked={ele.checked}
                                  onChange={(e) => {
                                    // onTextChanged(e);
                                    console.log(
                                      " check box e :",
                                      e.target.checked
                                    );
                                    SetTotalValueEntered((t_prevalue: any) => {
                                      if (
                                        ele.seller_settlement
                                          .settlement_eligible &&
                                        ele.seller_settlement
                                          .settlement_approved
                                      ) {
                                        SetOLList((prevalue: any) => {
                                          prevalue[i].checked =
                                            e.target.checked;
                                          let checked_data = prevalue.filter(
                                            (ele: any) => ele.checked
                                          );
                                          SetTotalAmountValue((prevalue1) => {
                                            if (prevalue[i].checked) {
                                              if (ele.seller_settlement.settlement_paid) {
                                                // prevalue[i].total_value =
                                                //   ele.cod_settlement_paid_amount;
                                                let sub_value =
                                                  Number(ele.amount) -
                                                  Number(
                                                    ele.seller_settlement.settlement_paid_amount
                                                  );
                                                prevalue[i].total_value = sub_value;
                                              } else {
                                                prevalue[i].total_value =
                                                  ele.amount;
                                              }
                                              prevalue[i].textarea = "";

                                              if (!t_prevalue) {
                                                // if (ele.cod_settlement_paid) {
                                                //   prevalue1 =
                                                //     Number(prevalue1) +
                                                //     Number(
                                                //       ele.cod_settlement_paid_amount
                                                //     );
                                                // } else {
                                                //   prevalue1 =
                                                //     Number(prevalue1) +
                                                //     Number(
                                                //       ele.collected_amount
                                                //     );
                                                // }
                                              }
                                            } else {
                                              prevalue[i].total_value = 0;
                                              prevalue[i].textarea = "";
                                              if (!t_prevalue) {
                                                // if (ele.cod_settlement_paid) {
                                                //   prevalue1 =
                                                //     Number(prevalue1) -
                                                //     Number(
                                                //       ele.cod_settlement_paid_amount
                                                //     );
                                                // } else {
                                                //   prevalue1 =
                                                //     Number(prevalue1) -
                                                //     Number(
                                                //       ele.collected_amount
                                                //     );
                                                // }
                                              }
                                            }

                                            let checked_data = prevalue.filter(
                                              (ele: any) => ele.checked
                                            );
                                            let total_value = 0;
                                            checked_data.map((ele: any) => {
                                              total_value =
                                                Number(total_value) +
                                                Number(ele.total_value);
                                            });
                                            if (!t_prevalue) {
                                              prevalue1 = total_value;
                                            }
                                            return prevalue1;
                                          });
                                          return [...prevalue];
                                        });
                                      } else {
                                        toast.error(
                                          "Not eligible for settlement"
                                        );
                                      }
                                      calculate_lrc();

                                      return t_prevalue;
                                    });
                                  }}
                                />
                                # {ele.id}
                              </div>
                              <div className="col-6 end ">
                                <p className="text-black">{ele.time}</p>
                              </div>
                            </div>
                            <div className="row pt-2">
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Order ID: #{ele.order_id}
                                </p>
                              </div>
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Date : {ele.date}
                                </p>
                              </div>
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Active :{ele.active}
                                </p>
                              </div>
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Sector :{ele.sector}
                                </p>
                              </div>
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Amount :{ele.amount}
                                </p>
                              </div>
                              <div className="col-6 pb-3">
                                <p className="text-black">
                                  Customer Name :{ele.customer.name}
                                </p>
                              </div>
                              {ele.seller_settlement.settlement_paid ? (
                                <div className="col-6 pb-3">
                                  <p className="text-black">
                                    Partially Paid Amount :
                                    {ele.seller_settlement.settlement_paid_amount}
                                  </p>
                                </div>
                              ) : null}

                              <div className="">
                                {ele.checked ? (
                                  <div className="row">
                                    <label className="col-6">
                                      Amount
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={ele.total_value}
                                        onChange={(e) => {
                                          let value_data = e.target.value;
                                          // setTimeout(() => {
                                          console.log("sdf", e.target.value);

                                          SetOLList((ol_prevalue: any) => {
                                            ol_prevalue[i].total_value =
                                              value_data;
                                            let checked_data =
                                              ol_prevalue.filter(
                                                (ele: any) => ele.checked
                                              );
                                            let all_total_value = 0;
                                            checked_data.map((ele: any) => {
                                              all_total_value =
                                                Number(all_total_value) +
                                                Number(ele.total_value);
                                            });
                                            SetTotalValueEntered(
                                              (te_prevalue) => {
                                                if (!te_prevalue) {
                                                  SetTotalAmountValue(
                                                    all_total_value
                                                  );
                                                }
                                                return te_prevalue;
                                              }
                                            );
                                            return [...ol_prevalue];
                                          });
                                          // calculate_lrc();
                                        }}
                                      />
                                    </label>
                                    <label className="col-6">
                                      Notes
                                      <textarea
                                        value={ele.textarea}
                                        onChange={(e) => {
                                          SetOLList((prevalue: any) => {
                                            prevalue[i].textarea =
                                              e.target.value;
                                            return [...prevalue];
                                          });
                                        }}
                                      ></textarea>
                                    </label>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    </>
                  ) : (
                    <div className="center p-3">
                      <div className="card p-3 w-100">
                        <div className="w-100 text-center">
                          <img
                            src={require("../../assets/img/not-found.png")}
                            className="no-data-img"
                          />
                        </div>
                        <p className="center pt-3">No Orders</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <p>Total Amount</p>
                <input
                  type="number"
                  className="form-control"
                  value={total_amount_value}
                  onChange={(e: any) => {
                    console.log("e on total amount :", e.target.value);
                    SetTotalValueEntered((prevalue: any) => {
                      if (!prevalue) {
                        prevalue = true;
                      }
                      return prevalue;
                    });
                    SetTotalAmountValue(e.target.value);
                    calculate_lrc();
                  }}
                />
                <p>{lrc_error_data}</p>
                <p>Notes</p>
                <textarea
                  value={note_value}
                  onChange={(e: any) => {
                    SetNoteValue(e.target.value);
                  }}
                ></textarea>
                <p>Image Upload</p>
                <div
                  className="Dropzone Highlight"
                  onDrop={onDrop}
                  onClick={openFileDialog}
                  style={{
                    cursor: props.disabled ? "default" : "pointer",
                  }}
                >
                  <input
                    ref={fileInputRef}
                    className="FileInputNone"
                    type="file"
                    multiple
                    accept=".jpg, .jpeg, .png, .webp, .gif"
                    onChange={(evt) => {
                      onFilesAdded(evt);
                    }}
                  />
                  <p className="text-black mt-3">Drag or Select Files</p>
                </div>
                {image_data.map((element: any, index: any) => (
                  <div
                    className="col-lg-2 col-md-3 col-4 p-1 position-relative"
                    key={index}
                  >
                    <img src={element.url} className="add_img" />
                    {element.file.size > 10 * 1024 * 1024 && (
                      <p className="error-message">
                        image size should be less than 10 MB
                      </p>
                    )}
                    <div
                      className="cursor text-center text-red over-img-close"
                      onClick={() => {
                        console.log("on remove");
                        SetImageData((prevValue: any) => {
                          let file_data = prevValue;
                          file_data.splice(index, 1);
                          prevValue = file_data;
                          console.log("file_data :", file_data);
                          return [...prevValue];
                        });
                      }}
                    >
                      X
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="end p-3 pt-1">
            <button
              type="button"
              className="btn text-danger btn-sm btn-radius px-3"
              onClick={(e) => {
                // post_accept();
                submit();
              }}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm btn-radius ms-3 px-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
