import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";

let myModal = {};


export default function AvailableDeliveryPartner(props: any) {
    const [id_value, SetIdValue] = useState(useParams());
    const [close_data, SetCloseData] = useState({
        action: "close",
    });
    const [delivery_partner_data, SetDeliveryPartnerData] = useState([])

    useEffect(() => {
        let myModal1 = document.getElementById("AvailableDeliveryPartnerModal");
        myModal1.addEventListener("hidden.bs.modal", function (event) {
            props.close(close_data);
        });
    }, []);

    useEffect(() => {
        if (props.open) {

            myModal = new bootstrap.Modal(
                document.getElementById("AvailableDeliveryPartnerModal"),
                {}
            );
            myModal.show();
            get_delivery_partner();
        }
    }, [props.open]);

    async function get_delivery_partner() {
        let pass_data = {
            get: {
                order_id: id_value.ord_id,
            },
        };
        let response: any = await api("/orders/get_delivery_partners", pass_data);
        if (response.status_code == 200) {
            let data = response.response.data;
            data.map((ele: any) => {
                ele.checked = false;
            })
            SetDeliveryPartnerData(data);
        }
    }


    return (
        <div
            className="modal fade"
            id="AvailableDeliveryPartnerModal"
            tabIndex={-1}
            aria-labelledby="ModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="  modal-title" id="ModalLabel">
                            <h5>Available Delivery Partner</h5>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                myModal.hide();
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="">
                            {delivery_partner_data.map((ele: any, index: any) => (
                                <div className="mb-2" key={index}>
                                    <input type="radio" checked={ele.checked} onChange={() => {
                                        SetDeliveryPartnerData((prevalue: any) => {
                                            prevalue.map((pre_ele: any, pre_index: any) => {
                                                if (index == pre_index) {
                                                    pre_ele.checked = true;
                                                } else {
                                                    pre_ele.checked = false;
                                                }
                                            })

                                            return [...prevalue];
                                        })
                                    }} />
                                    <p>Brand Name :{ele.brand_name}</p>
                                    <p>Delivery Partner Name :{ele.agent.name}</p>
                                    <p>{ele.available ? ("Available") : ("Not Available")}</p>
                                    <p>Distance : {ele.distance}</p>
                                    <p>Total Orders : {ele.total_orders}</p>
                                    <p>____________</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary me-3"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                myModal.hide();
                            }}
                        >
                            Close
                        </button>
                        {delivery_partner_data.some((ele: any) => ele.checked == true) ? (
                            <button
                                type="button"
                                className="btn btn-sm btn-secondary me-3"
                                onClick={() => {
                                    myModal.hide();

                                    let selected_data: any;
                                    delivery_partner_data.map((ele: any) => {
                                        if (ele.checked == true) {
                                            selected_data = ele;
                                        }
                                    })
                                    props.on_assign(selected_data);
                                }}
                            >
                                Assign Delivery Partner
                            </button>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>

    );
}