import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import { api } from "../../utils/Api";

let myModalAll: any;

export default function ModalAcceptRequestAll(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  const [ids, setIds] = useState([]);

  useEffect(() => {
    let myModal1 = document.getElementById("reqAcAllModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
     
      let id = [];
      props?.data?.data?.specifications?.map((ele, i) => {
        id.push(ele.id);
      });

      setIds(id);

      myModalAll = new bootstrap.Modal(
        document.getElementById("reqAcAllModal"),
        {}
      );
      myModalAll.show();
    }
  }, [props.open]);

  async function deleteUserRole() {
    let pass_data = {
      post: {
        ids: ids,
        status: "1",
      },
    };

    let data_res = await api(
      "/products/product_specificaton_update_request_status",
      pass_data
    );
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "save";
      item.value = data_res.response.data;
      SetCloseData(item);

      if (myModalAll) {
        myModalAll.hide();
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="reqAcAllModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Accept Changes
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to accept changes?
            <br />
            <h6>Product Details</h6>
            <tbody>
              {props?.data?.data?.product_details.category_name && (
                <tr>
                  <td>Category : </td>

                  <td>{props?.data?.data?.product_details.category_name}</td>
                </tr>
              )}

              {/* HSN */}
              {props?.data?.data?.product_details.hsn && (
                <tr>
                  <td>HSN : </td>
                  <td>{props?.data?.data?.product_details.hsn}</td>
                </tr>
              )}

              {/* GST */}
              {props?.data?.data?.product_details.gst && (
                <tr>
                  <td>GST : </td>
                  <td>{props?.data?.data?.product_details.gst}</td>
                </tr>
              )}

              {/* Preparing Duration */}
              {props?.data?.data?.product_details.preparing_duration && (
                <tr>
                  <td>Preparing Duration : </td>
                  <td>
                    {props?.data?.data?.product_details.preparing_duration}
                  </td>
                </tr>
              )}

              {/* GTIN */}
              {props?.data?.data?.product_details.gtin && (
                <tr>
                  <td>GTIN : </td>
                  <td>{props?.data?.data?.product_details.gtin}</td>
                </tr>
              )}

              {/* UPC */}
              {props?.data?.data?.product_details.upc && (
                <tr>
                  <td>UPC : </td>
                  <td>{props?.data?.data?.product_details.upc}</td>
                </tr>
              )}

              {/* EAN */}
              {props?.data?.data?.product_details.ean && (
                <tr>
                  <td>EAN : </td>
                  <td>{props?.data?.data?.product_details.ean}</td>
                </tr>
              )}

              {/* JAN */}
              {props?.data?.data?.product_details.jan && (
                <tr>
                  <td>JAN : </td>
                  <td>{props?.data?.data?.product_details.jan}</td>
                </tr>
              )}

              {/* ISBN */}
              {props?.data?.data?.product_details.isbn && (
                <tr>
                  <td>ISBN : </td>
                  <td>{props?.data?.data?.product_details.isbn}</td>
                </tr>
              )}

              {/* MPN */}
              {props?.data?.data?.product_details.mpn && (
                <tr>
                  <td>MPN : </td>
                  <td>{props?.data?.data?.product_details.mpn}</td>
                </tr>
              )}

              {/* Min Handling Time */}
              {props?.data?.data?.product_details.min_handling_time && (
                <tr>
                  <td>Min Handling Time : </td>
                  <td>
                    {props?.data?.data?.product_details.min_handling_time}
                  </td>
                </tr>
              )}

              {/* Max Handling Time */}
              {props?.data?.data?.product_details.max_handling_time && (
                <tr>
                  <td>Max Handling Time : </td>
                  <td>
                    {props?.data?.data?.product_details.max_handling_time}
                  </td>
                </tr>
              )}

              {/* Height */}
              {props?.data?.data?.product_details.height && (
                <tr>
                  <td>Height : </td>
                  <td>
                    {props?.data?.data?.product_details.height}{" "}
                    {props?.data?.data?.product_details.height_unit_name}
                  </td>
                </tr>
              )}

              {/* Width */}
              {props?.data?.data?.product_details.width && (
                <tr>
                  <td>Width : </td>
                  <td>
                    {props?.data?.data?.product_details.width}{" "}
                    {props?.data?.data?.product_details.width_unit_name}
                  </td>
                </tr>
              )}

              {/* Length */}
              {props?.data?.data?.product_details.length && (
                <tr>
                  <td>Length : </td>
                  <td>
                    {props?.data?.data?.product_details.length}{" "}
                    {props?.data?.data?.product_details.length_unit_name}
                  </td>
                </tr>
              )}

              {/* Weight */}
              {props?.data?.data?.product_details.weight && (
                <tr>
                  <td>Weight : </td>
                  <td>
                    {props?.data?.data?.product_details.weight}{" "}
                    {props?.data?.data?.product_details.weight_unit_name}
                  </td>
                </tr>
              )}
            </tbody>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              name="id"
              value={props.id}
              onClick={(e) => {
                deleteUserRole(e.target.name, e.target.value);
              }}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
